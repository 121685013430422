<template>
  <b-container fluid>
    <!-- <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading> -->
    <v-row>
      <v-col>
        <v-btn class="primary ma-2" :to="{ name: 'old_registration_dashboard' }"
          >Back</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- <OldRegOwnerInfo /> -->
        <old-reg-owner-info></old-reg-owner-info>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <OldBibaran
          :regId="this.$route.params.id"
          :isAddEditAuthorized="user_role_id == 2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <old-document-upload
          :isPostAuthorized="user_role_id == 2"
        ></old-document-upload>
      </v-col>
    </v-row>
  </b-container>
</template>

<script>
import OldRegOwnerInfo from "./ownerinfo/OldRegOwnerInfo.vue";
import OldBibaran from "./inputforms/OldBibaran.vue";
import OldDocumentUpload from "./docupload/OldDocumentUpload.vue";

export default {
  name: "oldregapplicationtab",
  props: {
    // id: {
    //   type: [String, Number]
    // },
    // regId: {
    //   type: [String, Number]
    // }
    // sn: {
    //   type: [String, Number]
    // }
  },
  components: {
    OldBibaran,
    OldRegOwnerInfo,
    OldDocumentUpload,
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      // results: []
      // Doc upload data
    };
  },
  mounted() {
    const urlApp = `api/registration/${this.$route.params.id}`;
    this.$axios.get(urlApp).then((response) => {
      this.reg = response.data.data;
    });
    console.log("regId: "+this.$route.params.id);
    if (this.sn === undefined) {
      this.sn = 1;
    }
  },
  computed: {
    // isLoading() {
    //   if (this.results === undefined || this.results.length === 0) {
    //     // registration value not yet initiated from api. Loading spinner active
    //     return true;
    //   }
    //   return false;
    // }
  },
  methods: {},
};
</script>

<style scoped></style>
