<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

.row {
  height: 100% !important;
}
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <NoticeList class="order-3" phaseId="5" :regId="this.$route.params.id" />

      </v-col>
      <v-col cols="12" md="6">
        <UploadDocumentList phaseId="5" :submitStatus="submitStatus" />


        <RegistrationAction v-if="this.$store.getters.forwardRevertActionAuthData" :regPhaseId="this.results.phase_id"
          :regDeskId="this.results.desk_user_role_id"
          :regCreatedByConsultancy="this.results.is_created_by_consultancy" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import RegistrationAction from "../subComponents/RegistrationAction.vue";
import api from "@/api/api";

import NoticeList from "./NoticeList.vue";
import UploadDocumentList from "./UploadDocumentList.vue";

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function,
    },
    id: {
      type: Number,
    },
    currentApplicationPhase: {
      type: [String, Number],
    },
    currentApplicationDesk: {
      type: [String, Number],
    },
    submitStatus: {
      type: Object,
    },
  },
  components: {
    RegistrationAction,
    NoticeList,
    UploadDocumentList
  },
  mounted() {
    this.results = this.$store.getters.registrationData;
  },
  data() {
    return {
      opened: 0,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      results: [],
    };
  },
  created() {
  },
  methods: {
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
