<template>
  <div>
    <b-container fluid class="mx-0">
      <v-row>
        <v-col cols="12" md="8" class="elevation-2">
          <v-toolbar dense dark flat color="primary">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <v-toolbar-title class="pt-2">{{
                results.houseowner_name
                }}</v-toolbar-title>
              </v-col>
              <v-col class="ma-0 pa-0">
                <div class="float-right">
                  <v-dialog v-model="mapDialog" max-width="700" height="400px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary" dark class="ma-2 white--text">View in Map
                        <v-icon right dark> mdi-map-legend </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 primary white--text">
                        House in Map
                      </v-card-title>

                      <v-card-text>
                        <div>
                          Coordinates: (
                          {{ results.latitude }}
                          {{ results.longitude }}
                          )
                          <!-- <v-text-field v-model="results.latitude" label="latitude" ></v-text-field>
                          <v-text-field v-model="results.longitude" label="longitude" ></v-text-field> -->
                        </div>
                        <div>
                          <!-- Map function that is used in Old registration also -->
                          <Map :inputLatitude="results.latitude" :inputLongitude="results.longitude"
                            :disableMarker="true"></Map>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="mapDialog = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-simple-table>
            <tbody>
              <tr>
                <td>
                  आर्थिक बर्ष: <b> {{ engNumToNep(results.fiscal_year) }} </b>
                </td>
                <td>
                  सम्पर्क नं: <b>{{ engNumToNep(results.contact_no) }}</b>
                </td>
                <td>
                  घरधनीको नाम : <b>{{ results.houseowner_name_np }}</b>
                </td>
                <td>
                  सडक न : <b>{{ engNumToNep(results.street_no) }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  करदाता संकेत न:
                  <b>{{ engNumToNep(results.houseowner_tax_no) }}</b>
                </td>
                <td>
                  जग्गा कित्ता न: <b>{{ engNumToNep(results.land_plot_no) }}</b>
                </td>
                <td>
                  आन्तरिक संकेत न:
                  <b>{{ engNumToNep(results.internal_code) }}</b>
                </td>
                <td>
                  वडा नं: <b>{{ engNumToNep(results.ward_no ? results.ward_no.toString() : "-" ) }}</b>
                </td>
              </tr>
              <tr>
                <!-- <td>
                  फारमको दर्ता मिति:
                  <b>{{
                    data ? engNumToNep(data.registration_date_np) : ""
                  }}</b>
                </td> -->
                <td>
                  फारमको दर्ता मिति:
                  <b>{{
                  results ? engNumToNep(results.registration_date_np) : ""
                  }}</b>
                </td>
                <!-- <td>
                  फारमको दर्ता नं:
                  <b>{{ data ? engNumToNep(data.registration_no) : "" }}</b>
                </td> -->
                <td v-if="this.$store.getters.nibedanBibaranAuthData">
                  <edit-new-registration-dialog></edit-new-registration-dialog>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="4" class="sober elevation-2">
          <v-card>
            <v-toolbar dense dark flat color="primary">
              <v-toolbar-title>Application Status</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="!isAppStatusLoaded">
              <v-skeleton-loader type="paragraph, sentences">
              </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
              <strong>{{ log }}</strong>
              <br />
              <strong> Phase: </strong> {{ results.phase }}
              <br />
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark x-small v-bind="attrs" v-on="on">
                    View all Logs
                  </v-btn>
                </template>
                <v-card class="elevation-2 my-2">
                  <v-toolbar color="primary" dark flat>
                    <v-toolbar-title>
                      {{ results.houseowner_name }}'s Application Log
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-simple-table class="sober elevation-2">
                    <thead>
                      <tr class="">
                        <th class="text-left">Date</th>
                        <th class="text-left">Phase</th>
                        <th class="text-left">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="log in all_logs" :key="log.reg_id" class="">
                        <td class="">{{ log.date_np }}</td>
                        <td>{{ log.reg_phase }}</td>
                        <td>{{ log.remarks }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-dialog>
            </v-card-text>
            <!-- <h4>View Application status logs</h4> -->
          </v-card>
        </v-col>
      </v-row>
    </b-container>
    <template>
      <v-row justify="center">
        <v-dialog persistent v-model="regDateDialog" max-width="400">
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="5" class="my-auto">
                  <strong>फारमको दर्ता मिति</strong>
                </v-col>
                <v-col>
                  <v-text-field dense outlined hide-details="auto" v-model="data.registration_date_np" label="" class=""
                    clearable ref="dateField"></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="5" class="my-auto"> <strong>फारमको दर्ता नं</strong> </v-col>
                <v-col
                  ><v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="data.registration_no"
                    label=""
                    class=""
                    clearable
                    ref="dateField"
                  ></v-text-field
                ></v-col>
              </v-row> -->
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="regDateDialog = false" depressed> Cancel </v-btn>
              <v-btn color="primary" @click="save()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import Map from "../../oldregistration/map/Map.vue";
import "leaflet/dist/leaflet.css";
import EditNewRegistrationDialog from "../../newregistration/components/EditNewRegistrationDialog.vue";

export default {
  name: "Ownerinfocard",
  mixins: [engNumConvert],
  components: {
    Map,
    EditNewRegistrationDialog,
  },
  data() {
    return {
      results: {
        latitude: "27.618448081884",
        longitude: "84.349935962698",
      },
      data: {
        registration_date_np: "",
        registration_no: "",
      },
      log: "",
      all_logs: "",
      dialog: false,
      regDateDialog: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      mapDialog: false, // For map dialog

      isAppStatusLoaded: false,
    };
  },
  props: {},
  mounted() {
    this.getDate();
    this.$root.$on("onFileForwardRevert", () => {
      this.getFileData();
    });
  },
  watch: {
    // This watches changes in route and run the mounted function after route id changes
    "$route.params.id": function (id) {
      this.getFileData();
    },
  },
  created() {
    this.getFileData();
  },
  methods: {
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          // this.registration.submitted_date_np = response.data.date_np;
          this.data.registration_date_np = response.data.date_np;
          // this.registration.registration_date = response.data.date;
        })
        .catch(() => { });
    },
    save() {
      const url = `api/registration/${this.$route.params.id}`;
      this.$axios.put(url, this.data).then((response) => {
        if (response.status == 200 || response.status === 201) {
          // this.isFormSubmitting = true;
          this.regDateDialog = false;
          this.snackbar.show = true;
          this.successfulMessage = "Data Updated successfully";
        }
      });
    },
    getFileData() {
      //set results to empty before receiving new data. This will also start the loading spinner
      this.isAppStatusLoaded = false;

      this.results = [];
      this.results = this.$store.getters.registrationData;
      this.data.registration_date_np = this.results.registration_date_np;
      this.checkRegDateDialog();
      this.el = this.results.phase_id;

      const regLogUrl = `api/registration/${this.$route.params.id}/log`;
      this.$axios.get(regLogUrl).then((response) => {
        this.all_logs = response.data.data;
        this.log = response.data.data[0].remarks;
        this.isAppStatusLoaded = true;
      });
    },
    checkRegDateDialog() {
      if (
        this.user_role_id == 2 &&
        (this.results.registration_date_np == null ||
          this.results.registration_date_np == "") &&
        (this.results.registration_no == null ||
          this.results.registration_no == "")
      ) {
        this.regDateDialog = true;
      }
    },
  },
};
</script>
