<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog v-model="dialog" max-width="60%">
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on">
            <v-btn :small="isSmall" class="primary">{{ displayText }}</v-btn>
          </a>
          <!-- <v-btn v-bind="attrs" v-on="on" style="width:40px;">{{ displayText }}</v-btn> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5"><strong>छाेटाे विवरण</strong></span>
          </v-card-title>
          <v-card-text class="ma-0 py-0">
            <div>
              <v-row>
                <v-col>
                  <v-autocomplete
                    dense
                    label="आर्थिक बर्ष"
                    v-model="registration.fiscal_year_id"
                    :rules="[required('आर्थिक बर्ष')]"
                    :items="fiscalyear"
                    item-value="id"
                    item-text="f_year"
                    :item-key="fiscalyear"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-select
                    dense
                    v-model="registration.construction_type_id"
                    label="निर्माणको विवरण"
                    :rules="[required('निर्माणको विवरण')]"
                    :items="constructionType"
                    item-value="construction_type_id"
                    item-text="construction_type_name"
                    :item-key="constructionType.id"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.houseowner_name_np"
                    dense
                    label="घरधनीको नाम"
                    :rules="[required('घरधनीको नाम')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.houseowner_name"
                    :rules="[required('Houseowner Name')]"
                    label="Houseowner Name"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="wards"
                    v-model="registration.ward_no"
                    :rules="required('वडा नं')"
                    label="वडा नं"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.land_plot_no"
                    label="जग्गा कित्ता न"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.contact_no"
                    :rules="requiredPhone('Contact No.')"
                    label="सम्पर्क नं."
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.location"
                    :rules="[required('ठेगाना')]"
                    label="ठेगाना"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.street_no"
                    :rules="[required('सडक न')]"
                    label="सडक नाम/ चाैडाई"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.houseowner_tax_no"
                    :rules="[required('करदाता संकेत न')]"
                    label="करदाता संकेत न"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.longitude"
                    label="Longitude"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="registration.latitude"
                    label="Latitude"
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <Map
                    @marker="addCoordinates($event)"
                    :inputLatitude="registration.latitude"
                    :inputLongitude="registration.longitude"
                  ></Map>
                </v-col>
                <v-col> </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false" depressed> Cancel </v-btn>
            <v-btn color="primary" v-on:click="save">
              <!-- अगाडि बढ्नुहोस -->
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import Map from "../../oldregistration/map/Map.vue";

export default {
  props: {
    displayText: {
      type: String,
      default: "Edit Info",
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [engNumConvert],
  components: {
    Map,
  },
  data() {
    return {
      dialog: false,
      registration: {
        old_reg_id: 0,
        fiscal_year_id: "",
        houseowner_name_np: "",
        houseowner_name: "",
        construction_type_id: "",
        land_plot_no: "",
        // file_code: "",
        ward_no: "",
        latitude: "",
        longitude: "",

        fiscal_year: "2078-1-31",

        registration_date_np: "",
        submitted_date_np: "",
        houseowner_name: "",
        houseowner_tax_no: "",

        internal_code: "-",
        contact_no: "",
        location: "",
        street_no: "",
      },
      munName: "",
      noOfWards: "",
      wards: [],
      fiscalyear: [],
      constructionType: [],
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?([९७७,977])?[०-९,0-9]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
    };
  },
  created() {
    this.getFiscalYear();
    this.getConstructionType();
    this.getWardNo();
  },
  mounted() {
    this.getDate();
    this.getRegistrationData();
  },
  methods: {
    getRegistrationData() {
      const regDataUrl = `api/registration/${this.$route.params.id}`;
      this.$axios
        .get(regDataUrl)
        .then((response) => {
          this.registration = response.data.data;
        })
        .catch((error) => {
          console.log(`registration api error:${error}`);
          // this.isLoading = false;
        });
    },
    save() {
      const regDataUrl = `api/registration/${this.$route.params.id}`;
      this.$axios
        .put(regDataUrl, this.registration)
        .then((response) => {
          this.registration = response.data.data;
          this.dialog = false;
          this.$root.$emit("owner-info-card");
        })
        .catch((error) => {
          console.log(`registration put api error:${error}`);
          // this.isLoading = false;
        });
    },
    getFiscalYear() {
      const fiscalUrl = "api/fiscal-year";
      this.$axios
        .get(fiscalUrl)
        .then((response) => {
          this.fiscalyear = response.data.data;
          // console.log(
          //   "Fiscal Year ID: " +
          //     this.fiscalyear[this.fiscalyear.length - 1]["id"]
          // );
          this.registration.fiscal_year_id =
            this.fiscalyear[this.fiscalyear.length - 1]["id"];
        })
        .catch(() => {});
    },
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          // this.registration.submitted_date_np = response.data.date_np;
          this.registration.submitted_date_np = response.data.date_np;
          // this.registration.registration_date = response.data.date;
        })
        .catch(() => {});
    },
    addCoordinates(coordinates) {
      // https://dev.to/freakflames29/how-to-pass-data-from-child-to-parent-in-vue-js-2d9m for reference to $emit
      this.registration.latitude = coordinates[0];
      this.registration.longitude = coordinates[1];
    },
    getConstructionType() {
      const constructionurl = "api/construction-type";
      this.$axios
        .get(constructionurl)
        .then((response) => {
          this.constructionType = response.data;
        })
        .catch(() => {});
    },
    getWardNo() {
      const municipality_url = "api/municipality";
      this.$axios
        .get(municipality_url)
        .then((response) => {
          // debugger;
          if (response.status === 200) {
            // this.municipality = response.data.data;
            this.munName = response.data.data[0].name.split(" ")[0];
            this.noOfWards = response.data.data[0].no_of_wards;
            // this.getAllWards();
            // this.wards.push(1);
            // console.log(`Chatta: ${this.noOfWards}`);
            // this.munName = "wooh woob";
            for (let i = 0; i < this.noOfWards; i++) {
              this.wards.push(this.engNumToNep(i + 1));
            }
            // console.log(`wards: ${this.wards}`);
          }
        })
        .catch((error) => {
          console.log("Navbar error while retrieving municipality");
          console.error(error);
        });
    },
  },
};
</script>