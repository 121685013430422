import { VueAxios } from '@/plugins/axios';

const state = {
    siteConfigurations: JSON.parse(localStorage.getItem('site_configurations')),
    // localConfigurations: JSON.parse(localStorage.getItem('local_configurations')),
};
const getters = {
    siteConfigurationsList: state => state.siteConfigurations,
    // localConfigurationsList: state => state.localConfigurations,
};
const actions = {
    async getSiteConfigurations({commit}){
        const response = await VueAxios.get(`api/site-configuration`);
        commit('setSiteConfigurations', response.data.data);
        localStorage.setItem('site_configurations', JSON.stringify(response.data.data));
    }
};
const mutations = {
    setSiteConfigurations: (state, payload) => (state.siteConfigurations = payload),
};

export default {
    state,
    getters,
    actions,
    mutations
};