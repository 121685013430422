import { VueAxios } from '@/plugins/axios';

const state = {
    buildingUse: [],    // भवनको प्रयोजन
    buildingCategory: [],
    buildingStructure: [],
    landUseArea: [],
    constructionType: [],
};

const getters = {
    buildingUseList: state => state.buildingUse,
    constructionTypeList: state => state.constructionType,
    buildingCategoryList: state => state.buildingCategory,
    buildingStructureList: state => state.buildingStructure,
    landUseAreaList: state => {

    }
};

const actions = {
    async getBuildingUseList({ commit }) {
        const response = await VueAxios.get(`api/building-use`);
        commit('setBuildingUseList', response.data);
    },
    async getBuildingCategoryList({ commit }) {
        const response = await VueAxios.get(`api/building-category`);
        commit('setBuildingCategoryList', response.data);
    },
    async getBuildingStructureList({ commit }) {
        const response = await VueAxios.get(`api/building-structure`);
        commit('setBuildingStructureList', response.data);
    },
    async getLandUseAreaList({commit}){
        const response = await VueAxios.get(`api/land-use-area`);
        commit('setLandUseAreaList', response.data.data);
    },

    async getConstructionTypeList({commit}){
        const response = await VueAxios.get(`api/construction-type`);
        commit('setConstructionTypeList', response.data.data);
    }
};

const mutations = {
    setBuildingUseList: (state, buildingUse) => (state.buildingUse = buildingUse),
    setConstructionTypeList: (state, constructionType) => (state.constructionType = constructionType),
    setBuildingCategoryList: (state, buildingCategory) => (state.buildingCategory = buildingCategory),
    setBuildingStructureList: (state, buildingStructure) => (state.buildingStructure = buildingStructure)
}

export default {
    state,
    getters,
    actions,
    mutations
};