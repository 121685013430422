<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <!-- Charkilla extra details Start -->
    <v-container class="border">
      <v-row>
        <v-col>
          <v-form ref="form" v-model="valid" >
            <v-simple-table class="sober">
              <thead>
                <tr>
                  <th class="text-center text-subtitle-1">दिशा</th>
                  <th class="text-center text-subtitle-1">जग्गाको नाप (ft) <span class="required-star">*</span></th>
                  <th class="text-center text-subtitle-1">भवनको नाप (ft) <span class="required-star">*</span></th>
                  <th class="text-center text-subtitle-1">
                    जग्गाको सिमानाबाट भवन सम्मको न्यूनतम दूरी (ft)
                    <span class="required-star">*</span>
                  </th>
                </tr>
              </thead>
              <tbody v-for="(c, index) in newCharkilla.charkilla" :key="index">
                <tr>
                  <td>
                    <span class="font-weight-bold font-s16">{{ c.direction }}</span>
                  </td>
                  <td>
                    <v-text-field
                      v-model="c.land_measurement"
                      :rules="[required('जग्गाको नाप')]"
                      label=""
                      type="number"
                      suffix="ft"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="c.building_measurement"
                      :rules="[required('भवनको नाप')]"
                      label=""
                      type="number"
                      suffix="ft"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="c.land_building_distance"
                      :rules="[required('This data')]"
                      label=""
                      type="number"
                      suffix="ft"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn primary v-if="isAddEditAuthorized" v-on:click="save" class="primary ma-2 px-6" :disabled="!valid">Save</v-btn>
            <v-snackbar v-model="snackbar.show" :color="snackbar.color">
              {{ successfulMessage }}
            </v-snackbar>
          </v-form>
        </v-col>
      </v-row>
      <!-- Charkilla extra details End -->
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ByelawsCharkilla",
  props: {
    isAddEditAuthorized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      valid: false,
      required(propertyType) {
        return (v) => !!v || `${propertyType} आवश्यक क्षेत्र हो ।`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} आवश्यक क्षेत्र हो ।`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      regFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !v || regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },

      landscapeType: [],

      date: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      directions: [
        { id: "e", value: "पुर्व" },
        { id: "w", value: "पश्चिम" },
        { id: "n", value: "उत्तर" },
        { id: "s", value: "दक्षिण" },
      ],
      directionsThrough: [
        { id: "front", value: "Front" },
        { id: "left", value: "Left" },
        { id: "right", value: "Right" },
        { id: "back", value: "Back" },
      ],
      charkilla: [],
      newCharkilla: {
        charkilla: [],
      },
    };
  },
  mounted() {
    this.getCharkilla();
    this.addkilla();
  },
  methods: {
    save() {
      this.isLoading = true;
      if (
        this.newCharkilla.charkilla[0].id === "null" ||
        this.newCharkilla.charkilla[0].id === 0 ||
        this.newCharkilla.charkilla[0].id === undefined
      ) {
        const saveUrl = `/api/registration/${this.$route.params.regId}/charkilla`;
        this.$axios
          .post(saveUrl, this.newCharkilla)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.isLoading = false;
              this.successfulMessage = "Successfull.";
              // Format charkilla direction order
              this.newCharkilla.charkilla = response.data.data;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/charkilla/${this.newCharkilla.charkilla[0].id}`;
        this.$axios
          .put(eurl, this.newCharkilla)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data updated successfully";
              this.newCharkilla.charkilla = response.data.data;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response.data.message);
          });
      }
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/charkilla/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.charkilla.map((item) => item.id).indexOf(id); // find index of your object
            this.charkilla.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCharkilla() {
      const url = `api/registration/${this.$route.params.regId}/charkilla`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          if (response.data.data.length !== 0) {
            this.newCharkilla.charkilla = [];
            this.newCharkilla.charkilla = response.data.data;
            var charlen = this.newCharkilla.charkilla.length;
            let directioncopy = [
              { id: "e", value: "पुर्व" },
              { id: "w", value: "पश्चिम" },
              { id: "n", value: "उत्तर" },
              { id: "s", value: "दक्षिण" },
            ];

            var charlen = this.newCharkilla.charkilla.length;

            if (this.newCharkilla.charkilla.length < 4) {
              for (let j = 0; j < charlen; j++) {
                var dir = this.newCharkilla.charkilla[j].direction;
                var dirIndex = directioncopy
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(dir);
                directioncopy.splice(dirIndex, 1);
              }

              for (let i = 1; i < 5 - charlen; i++) {
                this.newCharkilla.charkilla.push({
                  id: 0,
                  reg_id: this.regId,
                  direction: directioncopy[i - 1].value,
                  direction_through: "",
                  landscape_type_id: "",
                  landscape_type: "",
                  landscape_type_en: "",
                  road_width: "",
                  name: "",
                  plot_no: "",
                  actual_setback: "",
                  standard_setback: "",
                  land_measurement: "",
                  building_measurement: "",
                  land_building_distance: "",
                  created_by: "",
                });
              }
            }
          }

          this.isLoading = false; //stop the loading spinner
          // this.newCharkilla.clear();
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false; //stop the loading spinner
        });
    },
    addkilla() {
      for (let i = 0; i < 4; i++) {
        this.newCharkilla.charkilla.push({
          id: 0,
          reg_id: this.regId,
          direction: this.directions[i].value,
          direction_through: "",
          landscape_type_id: "",
          road_width: "",
          name: "",
          plot_no: "",
          actual_setback: "",
          standard_setback: "",
          land_measurement: "",
          building_measurement: "",
          land_building_distance: "",
        });
      }
    },
  },
};
</script>

<style scoped>
.font-s16 {
  font-size: 16px !important;
}
.required-star{
  color: red;
}
</style>