<template>
  <b-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-dialog v-model="dialog" max-width="600px" v-if="user_role_id == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Add New File
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New File</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Title"
                      hide-details="auto"
                      v-model="document.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      label="File input"
                      v-model="document.file_data"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-btn
                color="blue darken-1"
                @click="uploadDocument()"
                class="ml-4 mb-4 text-white"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in documentList" :key="i">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="auto">
                {{ item.title }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" v-if="user_role_id == 1">
                <custom-dialog
                  :click-dialog="deleteFile"
                  :regId="item.id"
                  click-type="delete"
                >
                  Delete
                </custom-dialog>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <inline-doc :src="basePath + item.file_data"></inline-doc>
            <!-- <img :src="basePath + item.file_data" alt=""> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <!-- <v-sheet class="text-center h1">आउदै छ </v-sheet> -->
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      dialog: false,
      valid: false,
      isLoading: false,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      formData: new FormData(),
      document: {
        id: 0,
        title: "",
        subtitle: "",
        file_data: "",
      },
      documentList: [],
      basePath: process.env.VUE_APP_BASE_URL,
    };
  },
  mounted() {
    this.getReferenceFiles();
  },
  methods: {
    getReferenceFiles() {
      const refUrl = `api/reference-files`;
      this.$axios.get(refUrl).then((response) => {
        this.documentList = response.data.data;
        // this.isLoading = false;
      });
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      // const formData = new FormData();

      this.buildFormData(this.formData, data);

      return this.formData;
    },

    uploadDocument() {
      const refUrl = `api/reference-files`;
      // const self = this;
      this.formData = this.jsonToFormData(this.document);
      this.$axios.post(refUrl, this.formData).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.successfulMessage = "Successfully updated";
          this.dialog = false;
          this.getReferenceFiles();
        }
      });
    },
    deleteFile(id) {
      const deleteurl = `api/reference-files/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>