import { VueAxios } from '@/plugins/axios';


// This form keeping in the store may be bad idea after all
// Let's keep a pause on the forms.js for now

const state = {
    application: [],
}

const getters = {}
const actions = {}
const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}