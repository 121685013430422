import { VueAxios } from '@/plugins/axios';

const state = {
    documentMaster: [],
};
const getters = {};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};