<template>
  <div>
    <v-row class="two-button-row mx-2">
      <v-col cols="auto">
        <v-btn
          v-if="user_role_id != 8"
          large
          depressed=""
          class="primary right subtitle-1"
          :to="{ name: 'consultantfiles' }"
        >
          कन्सल्टेन्टबाट दर्ता भएका निवेदनहरु
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="user_role_id != 8"
          large
          depressed=""
          class="primary right subtitle-1"
          :to="{ name: 'old_registration_dashboard' }"
        >
          पुरानो दर्ताहरु
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          large
          depressed=""
          class="primary right subtitle-1"
          :to="{ name: 'new_registration' }"
        >
          <v-icon left>mdi-plus</v-icon>
          नयाँ दर्ता
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <registration-data-table :isOld="false"></registration-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RegistrationDataTable from "../home/components/RegistrationDataTable.vue";

export default {
  name: "Home",
  components: {
    RegistrationDataTable,
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
    };
  },
};
</script>

<style>
</style>