import Vuex from 'vuex';
import Vue from 'vue';
import { VueAxios } from "@/plugins/axios";
import dropdowns from './modules/dropdowns';
import registration from './modules/registration';
import document_master from './modules/document_master';
import forms from "./modules/forms";
import auth from './modules/auth';
import user_configurations from "./modules/user_configurations";
import local_data from "./modules/local_data";


// Load Vuex here
Vue.use(Vuex);
// Vue.use(VueAxios); // Reform this error

// Create store
export default new Vuex.Store({
    modules: {
        dropdowns,
        registration,
        auth,
        document_master,
        forms,
        user_configurations,
        local_data
    }
});