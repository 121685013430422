<template>
  <div class="indigo lighten-5 height-full">
    <nav class="top-nav white">
      <h3 class="text-uppercase text-center indigo--text pt-4">Admin Dashboard</h3>
    </nav>
    <div class="flex-container">
      <aside class="side-nav white">
        <v-list>
          <v-subheader>Links</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in adminLinks"
              :key="i"
              :to="{ name: item.link }"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </aside>
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
    <!-- <admin-navbar></admin-navbar> -->
    <!-- <v-row>
      <v-col cols="2" class="mt-4">
        <v-navigation-drawer permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6 text-uppercase">
                Admin
              </v-list-item-title>
              <v-list-item-subtitle>Dashboard</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item
              v-for="item in adminLinks"
              :key="item.title"
              link
              :to="{ name: item.link }"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10">
        <router-view></router-view>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import AdminNavbar from "./AdminNavbar";
export default {
  name: "App",
  components: {
    // AdminNavbar,
  },
  data() {
    return {
      adminLinks: [
        {
          title: "Users and Desk",
          link: "admin_user_and_desk",
          icon: "mdi-account",
        },
        {
          title: "Authority Transfer",
          link: "authority_transfer",
          icon: "mdi-account-arrow-right",
        },
        // { title: "Checklist", link: "admin_checklist" },
        // { title: "Document Master", link: "admin_document_master" },
        // { title: "Forward Revert Matrix", link: "admin_frmatrix" },
        // { title: "Site Configuration", link: "admin_site_configuration" },
        // { title: "Notice List", link: "notice_list" },
        {
          title: "Ward Assignment",
          link: "admin_ward_assignment",
          icon: "mdi-flag",
        },
        {
          title: "GharNaksha Home",
          link: "home",
          icon: "mdi-home",
        },
      ],
    };
  },
};
</script>

<style scoped>
.height-full {
  height: 100%;
}

.top-nav {
  /* background-color: red; */
  height: 4rem;
}
.side-nav {
  /* display: flex; */
  /* flex-direction: column; */
  /* background-color: white; */
  height: 100vh; /* Change */
  width: 16rem;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.main-content {
  padding: 4rem;
}
</style>