<template>
  <b-container fluid>
    <!-- <v-card class="mb-12" color="grey lighten-1" height="auto"> -->
    <!-- <v-app id="inspire"> -->
    <!-- <div> -->
    <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
    <!-- </div> -->
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>

    <v-dialog v-model="dialog" persistent max-width="800px" v-if="userRoleId == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ma-4" dark v-bind="attrs" v-on="on" @click="clearAllData()">
          Create New Consultancy
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">New Consultancy Form</span>
        </v-card-title>
        <v-card-text>
          <v-form v-if="userRoleId == 1" ref="form" v-model="valid" style="margin-bottom: 1rem">
            <b-container>
              <v-row align="center">
                <v-col md="6">
                  <v-radio-group v-model="newconsultancy.is_consulting_firm" :mandatory="true">
                    <v-radio label="Consulting Firm" v-bind:value="true"></v-radio>
                    <v-radio label="Freelancer" v-bind:value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="2"> Registration Number </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.registration_number"
                    class="custom-text-field"
                    label=""
                    height="10"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <v-row align="center">
                <v-col cols="2"> District </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="newconsultancy.district_id"
                    :items="districts"
                    item-value="id"
                    item-text="district_name"
                    hide-details="auto"
                    :key="id"
                    solo
                  ></v-autocomplete>
                </v-col>
                <v-col md="2"> Location </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="newconsultancy.location"
                    hide-details="auto"
                    label=""
                    height="10"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col md="2"> Registration Date </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="newconsultancy.registration_date_np"
                    label=""
                    class="custom-text-field"
                    hide-details="auto"
                    ref="dateField"
                    required
                    hint="2078-11-12 Nepali Date"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col md="2"> Consultancy Name </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="newconsultancy.name"
                    label=""
                    height="10"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2"> Phone Number </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.phone_no"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2"> Email </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.email"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2"> First Name </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.person_first_name"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2"> Last Name </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.person_last_name"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2"> Username </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.username"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2"> N.E.C Number </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.person_nec_no"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"> Account Expiry Date </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.expiry_date"
                    hide-details="auto"
                    hint="2078-02-21"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </b-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="userRoleId == 1"
            primary
            v-on:click="saveConsultancy"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <br /> -->
    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
    <!-- <v-toolbar color="primary mt-2 pa-0" dark dense flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar> -->
    <div>
      <v-simple-table class="sober" width="100%">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable">S. No.</th>
              <th class="text-left registrationtable">Consultancy Name</th>
              <th class="text-left registrationtable">Registration Number</th>
              <th class="text-left registrationtable">Location</th>
              <th class="text-left registrationtable">Registration Date</th>
              <th class="text-left registrationtable">Phone No</th>
              <th class="text-left registrationtable">Email</th>
              <th class="text-left registrationtable">Engineer Name</th>
              <th class="text-left registrationtable">NEC No.</th>
              <th class="text-left registrationtable" v-if="userRoleId == 1" width="14%">Action</th>
            </tr>
          </thead>
          <tbody>
            <!-- Search Function for consultancy pending -->
            <tr>
              <td></td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.name"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.registration_number"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.location"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.registration_date_np"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.phone_no"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.email"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.person_name"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.person_nec_no"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
            </tr>
            <tr
              class="registrationtable"
              v-for="consultancyList in consultancy"
              :key="consultancyList.id"
            >
              <td class="registrationtable">{{ consultancyList.sno }}</td>
              <td class="registrationtable">{{ consultancyList.name }}</td>
              <td class="registrationtable">
                {{ consultancyList.registration_number }}
              </td>
              <td class="registrationtable">{{ consultancyList.location }}</td>
              <td class="registrationtable">
                {{ consultancyList.registration_date_np }}
              </td>
              <td class="registrationtable">{{ consultancyList.phone_no }}</td>
              <td class="registrationtable">{{ consultancyList.email }}</td>
              <td class="registrationtable">
                {{ consultancyList.person_name }}
              </td>
              <td class="registrationtable">
                {{ consultancyList.person_nec_no }}
              </td>
              <td v-if="userRoleId == 1">
                <v-btn class="primary" small v-on:click="editConsultancy(consultancyList.id)"
                  >Edit</v-btn
                >
                <custom-dialog
                  :click-dialog="deleterecordConsultancy"
                  :regId="consultancyList.id"
                  click-type="delete"
                  >Delete</custom-dialog
                >
                <custom-dialog
                  :click-dialog="renewConsultancy"
                  :regId="consultancyList.id"
                  click-type="renew"
                  v-if="consultancyList.is_renewed != true"
                  >Renew</custom-dialog
                >
                <br />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- </v-app> -->
    <!-- </v-card> -->
  </b-container>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
// import * as nepaliDatePicker from "../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
// import "../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";

export default {
  name: "consultancy",

  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      valid: false,
      date: "",
      consultancy: [],
      districts: [],
      newconsultancy: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        name: "",
        location: "",
        district_id: "",
        registration_date_np: "",
        registration_date: "",
        phone_no: "",
        email: "",
        expiry_date: "",
        person_first_name: "",
        person_last_name: "",
        person_name: "",
        person_nec_no: "",
        username: "",
      },
      searchKeys: {
        name: "",
        registration_number: "",
        location: "",
        registration_date_np: "",
        phone_no: "",
        email: "",
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      // phone_no: "",
      // email: "",
      // expiry_date: "",
      dialog: false,
    };
  },
  created() {
    this.getDistricts();
  },
  watch: {
    "newconsultancy.email": function (val) {
      this.newconsultancy.username = this.newconsultancy.email;
    },
  },
  computed: {
    personFullName: {
      // getter
      get: function () {
        return this.newconsultancy.person_first_name + " " + this.newconsultancy.person_last_name;
      },
      set: function (newValue) {
        this.newconsultancy.person_name = newValue;
      },
    },
  },
  mounted() {
    // const { dateField } = this.$refs;
    // if (dateField) {
    //   $(dateField.$el).nepaliDatePicker({
    //     dateFormat: "%y-%m-%d",
    //     closeOnDateSelect: true
    //   });
    //   $(dateField.$el).on("dateSelect", event => {
    //     this.newconsultancy.registration_date_np = event.datePickerData.formattedDate;
    //   });
    // }
    this.getConsultancy();
  },
  methods: {
     getConsultancy() {
      return new Promise((resolve, reject) => {
        const ConsultancyListUrl = "api/consultancy";
          this.isLoading = true;
          VueAxios.get(ConsultancyListUrl)
            .then((response) => {
              // debugger;
              this.consultancy = response.data.data;
              this.consultancy = this.consultancy.map((d, index) => ({
                ...d,
                sno: index + 1,
              }));
              // this.newconsultancy.clear();
              this.isLoading = false;
              resolve(this.consultancy);
            })
            .catch((error) => {
              console.log(`api error:${error}`);
              this.isLoading = false;
              reject(error);
            });
        
      });
    },
    getDistricts() {
      const districtUrl = "api/districts";
      VueAxios.get(districtUrl)
        .then((response) => {
          // debugger;
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    clearAllData() {
      // this.newconsultancy.id = 0;
      // this.newconsultancy.reg_id = this.registrationId;
      // this.newconsultancy.is_consulting_firm = "";
      // this.newconsultancy.registration_number = "";
      // this.newconsultancy.name = "";
      // this.newconsultancy.email = "";
      // this.newconsultancy.location = "";
      // this.newconsultancy.district_id = "";
      // this.newconsultancy.registration_date_np = "";
      // this.newconsultancy.registration_date = "";
      // this.newconsultancy.person_name = "";
      // this.newconsultancy.expiry_date = "";
      // this.newconsultancy.person_nec_no = "";
      this.$refs.form.reset();
    },
    saveConsultancy() {
      this.isLoading = true;
      //   debugger;
      if (
        this.newconsultancy.id === "null" ||
        this.newconsultancy.id === 0 ||
        this.newconsultancy.id === undefined
      ) {
        const saveUrl = "/api/consultancy";
        this.newconsultancy.person_name =
          this.newconsultancy.person_first_name + " " + this.newconsultancy.person_last_name;
        VueAxios.post(saveUrl, this.newconsultancy)
          .then((response) => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.consultancy.push(response.data.data);
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              this.$refs.form.reset();
              this.dialog = false;
              this.getConsultancy();
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        this.newconsultancy.person_name =
          this.newconsultancy.person_first_name + " " + this.newconsultancy.person_last_name;
        const eurl = `/api/consultancy/${this.newconsultancy.id}`;
        VueAxios.put(
          eurl,
          this.newconsultancy,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            _method: "patch",
          },
          {}
        )
          .then((response) => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.successfulMessage = "Data has been updated successfully";
              this.$refs.form.reset();
              this.newconsultancy.id = 0; // reset the edit id
              this.dialog = false;
              this.getConsultancy().then((response) => {
                this.snackbar.show = true;
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editConsultancy(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/consultancy/${this.$route.params.id}`;
      const url = `api/consultancy/${id}`;
      VueAxios.get(url).then((response) => {
        // debugger;
        this.newconsultancy = response.data.data;
        if (this.newconsultancy.person_name != null) {
          var names = this.newconsultancy.person_name.split(" ");
          this.newconsultancy.person_first_name = names[0];
          this.newconsultancy.person_last_name = names[names.length - 1];
        }
        this.dialog = true;
        console.log(response.data.data);
      });
    },
    deleterecordConsultancy(id) {
      const deleteurl = `api/consultancy/${id}`;
      this.isLoading = true;
      VueAxios.delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.isLoading = false;
            this.successfulMessage = "Data deleted successfully";
            const i = this.consultancy.map((item) => item.id).indexOf(id); // find index of your object
            this.consultancy.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    renewConsultancy(id) {
      const renewurl = `api/consultancy/${id}/renew`;
      this.isLoading = false;
      VueAxios.post(renewurl)
        .then((response) => {
          this.isLoading = true;
          if (response.status === 200 || response.status === 201) {
            this.consultancy.push(response.data.data);
            this.snackbar.show = false;
            this.successfulMessage = response.data.message;
            this.getConsultancy().then((response) => {
              this.snackbar.show = true;
            });

          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    searchConsultancy() {
      const searchUrl = "api/consultancy/search";
      this.$axios
        .post(searchUrl, this.searchKeys)
        .then((response) => {
          this.consultancy = response.data.data;
          this.consultancy = this.consultancy.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped></style>
