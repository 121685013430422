<style scoped>
th {
  font-size: 15px;
}
.required-star {
  color: red !important;
}
</style>

<template>
  <div>
    <v-container>
      <!-- loading component Start -->
      <!-- <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading> -->
      <!-- loading component End -->

      <!-- Back Button  -->
      <v-row
        ><v-col>
          <v-btn
            class="primary"
            :to="{
              name: 'application_tab',
              params: { id: this.$route.params.regId },
            }"
            ><v-icon>mdi-arrow-left</v-icon>Back to application tab</v-btn
          ></v-col
        >
        <v-spacer></v-spacer
      ></v-row>
      <!-- Back button end -->
    </v-container>

    <!-- Byelaws Charkilla START-->
    <byelaws-charkilla :isAddEditAuthorized="isAddEditAuthorized" class="mb-4"></byelaws-charkilla>
    <!-- Byelaws Charkilla END -->

    <!-- Byelaws Standard Form -->
    <byelaws-standards-form :isAddEditAuthorized="isAddEditAuthorized" class="mb-4"></byelaws-standards-form>
    <!-- Byelaws Standard Form -->

    <!-- Key Value Pairs Form START -->
    <byelaws-key-value-form :isAddEditAuthorized="isAddEditAuthorized"></byelaws-key-value-form>
    <!-- Key Value Pairs Form END -->
  </div>
</template>

<script>
// import _ from "lodash";
import ByelawsStandardsForm from "@/byelaws/ByelawsStandardsForm";
import ByelawsCharkilla from "@/byelaws/ByelawsCharkilla";
import ByelawsKeyValueForm from "@/byelaws/ByelawsKeyValueForm";
export default {
  name: "Byelaws",
  components: {
    ByelawsStandardsForm,
    ByelawsCharkilla,
    ByelawsKeyValueForm,
  },
  props: {
    nextStep: {
      type: Function,
    },
    regId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    isPostAuthorized: {
      type: Boolean,
    },
  },

  data() {
    return {
      isValid: true,
      isLoading: true,
      user_role_id: localStorage.getItem("auth_user_role_id"),
    };
  },

  computed: {
    isAddEditAuthorized() {
      const registrationData = this.$store.getters.registrationData;
      if (
        (this.user_role_id == 2 || this.user_role_id == 8) &&
        registrationData.desk_user_role_id == this.user_role_id &&
        registrationData.phase_id != 6
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    // this.getChecklistType();
  },
  mounted() {
    // this.getRegistrationKeyValuePairs();
  },
  methods: {},
};
</script>
