<template>
  <div>
    <div v-if="!isDataLoaded">
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> नोटिस तथा सर्टिफिकेटहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="list-item, list-item, list-item, list-item">
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> नोटिस तथा सर्टिफिकेटहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="sober elevation-2">
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in noticeList" :key="i.id">
                  <template
                    v-if="(!i.consultancy_view_only || user_role_id == 8) && (!i.official_view_only || user_role_id != 8)">
                    <td>{{ i.notice_master_title }}</td>
                    <td>
                      <pdf-dialog :src="basePath + 'api/registration/' + regId + '/' + i.notice_master_api_keyword"
                        displayText="Print"></pdf-dialog>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>

</template>
<script>
import api from "@/api/api";

export default {
  name: "NoticeList",
  props: {
    // constructionTypeId: {
    //   type: [Number, String]
    // },
    phaseId: {
      type: [Number, String]
    },
    regId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      opened: 0,
      basePath: process.env.VUE_APP_BASE_URL,
      noticeList: [],
      user_role_id: localStorage.getItem("auth_user_role_id"),
      // naamsaariTippani: `api/registration/${this.regId}/naamsaari-tippani`,
      isDataLoaded: false,

      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  computed: {
    constructionTypeIdStr() {
      return this.$store.getters.registrationData.construction_type_id.toString();
    }
  },
  created() {
    this.getPhaseNoticeMaster();
  },
  methods: {
    async getPhaseNoticeMaster() {
      try {
        // Construction type id comes in as integers, convert to int and process;
        this.isDataLoaded = false;
        let response = await api.documents.phaseNoticeMaster(this.constructionTypeIdStr, this.phaseId);
        this.noticeList = response.data;
        this.isDataLoaded = true;
      }
      catch (error) {
        console.log(error);
        this.isDataLoaded = true;
      }
    }
  },


};
</script>