export default axios => ({
    // भू-उपयोग क्षेत्र
    landUseArea() {
        return axios.get("api/land-use-area");
    },

    buildingUse() {
        return axios.get("api/building-use");
    },

    buildingCategory() {
        return axios.get("api/building-category");
    },

    buildingStructure() {
        return axios.get("api/building-structure");
    },
    // तलाको नाम मात्र हो यार
    floorNames() {
        return axios.get("api/floor-names");
    },
    // All district list
    districts() {
        return axios.get("api/districts");
    },
    // That one row from municipality table
    municipality() {
        return axios.get("api/municipality");
    },
    // List of consultancy
    consultancyList() {
        return axios.get("api/consultancy");
    },
    // List of Landowner Type
    landownerType() {
        return axios.get("api/landowner-type");
    },
    // List of applicant Type
    applicantType() {
        return axios.get("api/applicant-type");
    },
    constructionType() {
        return axios.get("api/construction-type");
    },
    phaseList() {
        return axios.get("api/phase-list");
    },
    officialUserList() {
        return axios.get("api/user/municipal");
    },

    // Remove this from dropdown later
    getAssignAuthority(){
        return axios.get("api/assign-authority/status");
    },

    saveAssignAuthority(payload) {
        return axios.post("api/assign-authority", payload);
    },
    
    userRoleList(){
        return axios.get("api/user-role")
    },

});