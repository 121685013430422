import Vue from "vue";
import VueRouter from "vue-router";
import AdminChecklist from "../modules/admin/AdminChecklist.vue"
import AdminHome from "../modules/admin/AdminHome.vue"
import AdminFRMatrix from "../modules/admin/AdminFRMatrix.vue"
import AdminWardAssignment from "../modules/admin/AdminWardAssignment.vue"
import AdminDocumentMaster from "../modules/admin/AdminDocumentMaster.vue"
import AdminNoticeList from "../modules/admin/AdminNoticeList.vue";
import AdminAuthorityTransfer from "../modules/admin/AdminAuthorityTransfer.vue";
import AdminUserAndDesk from "../modules/admin/AdminUserAndDesk.vue";
import Index from "../modules/Index.vue";

import AreaFiftyOne from "../modules/test-files/AreaFiftyOne.vue";


const roleId = window.localStorage.getItem("auth_user_role_id");
// console.log(`role: ${roleId}`)


const isAdmin = (to, from, next) => {
    console.log("Behold, entering admin");
    if (roleId == 1) {
        next();
    } else {
        next({
            name: "home" // redirect home if user not admin
        });
    }
};

const routes = [
    // Let's make a parent path admin and child routes under it
    {
        path: "/admin",
        name: "admin_home",
        component: AdminHome,
        // path: "/",
        // name: "index",
        // component: Index,
        beforeEnter: isAdmin,
        // Nested routers inherit component from parent if defined properly in like Index.vue
        children: [
            {
                path: "checklist",
                name: "admin_checklist",
                component: AdminChecklist
            },
            {
                path: "frmatrix",
                name: "admin_frmatrix",
                component: AdminFRMatrix
            },
            {
                path: "documentmaster",
                name: "admin_document_master",
                component: AdminDocumentMaster
            },
            {
                path: "wardassignment",
                name: "admin_ward_assignment",
                component: AdminWardAssignment
            },
            {
                path: "notice/all",
                name: "notice_list",
                component: AdminNoticeList
            },
            {
                path: "authority-transfer",
                name: "authority_transfer",
                component: AdminAuthorityTransfer
            },
            {
                path: "user-and-desk",
                name: "admin_user_and_desk",
                component: AdminUserAndDesk
            },

        ]
    }
]

export default routes;