<template>
  <v-container>
    <v-layout row>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      ></loading>
      <v-flex style="text-align: center">
        <!-- <v-img src="http://cdn.wallpapersafari.com/7/86/gqiGH7.jpg" height="50px" width="50px"> -->

        <v-container class="sober fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <img
                :src="require('@/assets/images/logo-light-2.png')"
                width="140px"
              />
              <h4>GharNaksha Software</h4>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>GharNaksha User Creation</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-model="username"
                      name="username"
                      label="Username"
                      type="text"
                      autocomplete="new-password"
                      :rules="usernameRules"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="first_name"
                      name="firstname"
                      label="First Name"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="last_name"
                      name="lastname"
                      label="Last Name"
                    >
                    </v-text-field>
                    <v-text-field v-model="email" name="email" label="Email">
                    </v-text-field>
                    <v-text-field
                      v-model="designation"
                      name="designation"
                      label="Designation"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="password"
                      name="password"
                      label="Password"
                      type="password"
                      autocomplete="new-password"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="reTypedPassword"
                      name="retype_password"
                      :rules="[requiredSamePassword()]"
                      label="Re-type Password"
                      type="password"
                    >
                    </v-text-field>
                    <v-select
                      v-model="user_role_id"
                      :items="userRoles"
                      item-value="id"
                      item-text="role_name"
                      dense
                    ></v-select>
                  </v-form>
                  <v-snackbar v-model="snackbar.show" :color="snackbar.color">
                    {{ message }}
                  </v-snackbar>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-on:click="register"
                      >Register</v-btn
                    >
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Register",
  components: {},
  data() {
    return {
      requiredSamePassword() {
        return (v) =>
          this.password == this.reTypedPassword || `Must be same as password`;
      },
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      password: "",
      user_role_id: 0,
      reTypedPassword: "",
      token: "",
      message: "",
      snackbar: {
        show: false,
        message: this.message,
        color: "success",
      },
      isLoading: false,
      userRoles: [],
      usernameRules: [
        (v) => /^[\S]+$/.test(v) || "No space in username allowed",
      ],
    };
  },

  created() {
    const url = `api/user-role`;
    VueAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      //    debugger;
      this.userRoles = response.data.data;
    });
  },
  methods: {
    register() {
      this.isLoading = true;
      const url = "api/register";
      VueAxios.post(url, {
        username: this.username,
        password: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        designation: this.designation,
        user_role_id: this.user_role_id,
      })
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.message = "User Created Successfully!";
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.message = "Error in creating the user!!";
        });
      // .catch(error => this.error = error.response.data.errors);
    },
  },
};
</script>
