<template>
  <div>
    <v-row>
      <v-col v-for="n in documentList" :key="`${n.sn}-content`">
        <doc-upload
          :apiUrl="n.apiUrl"
          :docTitle="n.docTitle"
          file-size="5"
          :isPostAuthorized="isPostAuthorized"
          :show="false"
        ></doc-upload>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const COMPONENT_MAP = {
  1: "Map",
  2: "Ghardhanikophoto"
};

export default {
  name: "olddocumentupload",
  props: {
    isPostAuthorized: Boolean
  },
  data() {
    return {
      el: Number(this.sn),
      // id: this.$route.params.id,
      reg: [],
      formData: new FormData(),
      documentList: [
        {
          sn: 1,
          docTitle: "भवनको नक्सा",
          docTitleShort: "१) भवनको नक्सा",
          apiUrl: `api/registration/${this.$route.params.id}/documents/map`
        },
        {
          sn: 2,
          docTitle: "प्रमाण पत्र कागजातहरु",
          docTitleShort: "२) प्रमाण पत्र कागजातहरु",
          apiUrl: `api/registration/${this.$route.params.id}/documents/legal-document`
        },
        {
          sn: 3,
          docTitle: "अन्य कागजातहरु",
          docTitleShort: "३) अन्य कागजातहरु",
          apiUrl: `api/registration/${this.$route.params.id}/documents/photo`
        }
      ]
    };
  },
  mounted() {
    const urlApp = `api/registration/${this.$route.params.id}`;
    this.$axios.get(urlApp).then(response => {
      this.reg = response.data.data;
    });
    console.log(`route:${this.$route.params.id}`);
    if (this.sn === undefined) {
      this.sn = 1;
    }
  },
  methods: {
    nextStep(n) {
      if (n === this.documentList.sn) {
        this.el = 1;
      } else {
        this.el += n;
      }
    }
  }
};
</script>

<style scoped>
.vstepperStep {
  width: 300px;
  padding: 15px;
}
</style>
