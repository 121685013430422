<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-container class="pa-0" dense>
      <v-row class="" dense>
        <v-col xs12 md12 lg12>
          <v-card class="pa-0">
            <v-btn class="primary my-2" :to="{ name: 'home' }">Back</v-btn>
          </v-card>
          <v-card class="pa-0"> </v-card>
        </v-col>
      </v-row>

      <v-flex class="elevation-12" dense>
        <v-card class="d-flex flex-row">
          <v-card class="d-flex flex-column col-3">
            <v-card class="nibedanBibaran elevation-12">
              <v-toolbar color="primary" dark>
                <v-toolbar-title> Analyse {{ list }}</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" class="mx-4" v-model="valid">
                <!-- Fiscal Year
                <v-select 
                  v-model="building.selectedFiscalYear" 
                  :items="fiscalYears" 
                  item-value="id"
                  :reduce="f_year => f_year.id" 
                  item-text="f_year"
                  :key="id"
                  dense
                  multiple>
                </v-select> -->

                Ward Number
                <v-select v-model="building.ward" :items="wards" dense multiple> </v-select>

                <!-- <v-select
                  v-model="building.ward"
                  :items="wardName"
                  item-value="id"
                  :reduce="ward_name => ward_name.id" 
                  item-text="ward_name"
                  :key="id"
                  dense
                  multiple
                >
                </v-select> -->

                No. of storey
                <v-select
                  v-model="building.selectedStorey"
                  :items="storey"
                  @change="onchange()"
                  dense
                  multiple
                >
                </v-select>

                <!-- Year of Construction (BS)
                <v-select 
                  v-model="building.selectedYear" 
                  :items="year" 
                  dense
                  multiple>
                </v-select> -->
                Construction Type
                <v-select
                  v-model="building.selectedConstructionType"
                  :items="constructionType"
                  item-value="id"
                  :reduce="construction_type_name => construction_type.id"
                  item-text="construction_type_name"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>

                structure
                <v-select
                  v-model="building.selectedStructure"
                  :items="structure"
                  item-value="id"
                  :reduce="building_structure_type => building_structure_type.id"
                  item-text="building_structure_type"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>

                <!-- Irregularity
                <v-select 
                  v-model="building.selectedIrregularity" 
                  :items="irregularity" 
                  dense
                  multiple>
                </v-select> -->

                <!-- Area of building
                <v-select 
                  v-model="building.selectedAreaOfBuilding" 
                  :items="areaOfBuilding"
                  dense
                  multiple>
                </v-select> -->

                <!-- Type of roof
                <v-select 
                  v-model="building.selectedTypeOfRoof" 
                  :items="typeOfRoof" 
                  item-value="id"
                  :reduce="building_ceiling_material_type => building_ceiling_material_type.id" 
                  item-text="building_ceiling_material_type"
                  :key="id"
                  dense
                  multiple>
                </v-select> -->

                भवनको वर्गिकरण
                <v-select
                  v-model="building.selectedBuildingCategory"
                  :items="buildingCategory"
                  item-value="id"
                  :reduce="building_category_type => building_category_type.id"
                  item-text="building_category_type"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>
                <br />
                <v-snackbar
                  v-model="snackbar.show"
                  :bottom="y === 'bottom'"
                  :color="snackbar.color"
                  :left="x === 'left'"
                  :multi-line="mode === 'multi-line'"
                  :right="x === 'right'"
                  :timeout="3000"
                  :top="y === 'top'"
                  :vertical="mode === 'vertical'"
                >
                  {{ successfulMessage }}
                </v-snackbar>
                <v-btn
                  primary
                  v-on:click="save"
                  v-if="isPostAuthorized"
                  class="primary"
                  :disabled="!valid"
                  >Submit</v-btn
                >
                <!-- {{center}} -->
              </v-form>
            </v-card>
          </v-card>

          <v-card class="d-flex flex-column col-9">
            <v-card class="d-flex flex-row">
              <div class="col-md-12 map" style="height:800px;">
                <l-map :zoom="zoom" :center="center" v-resize="onResize">
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-polygon :lat-lngs="geojson.coordinates" color="red" />
                  <l-geo-json
                    v-if="show"
                    :geojson="geojson"
                    :options="options"
                    :options-style="styleFunction"
                  />
                  <!-- <l-marker :lat-lng="marker" /> -->
                </l-map>
              </div>
            </v-card>
            <!-- <v-card class="d-flex flex-row"> -->

            <GChart
              class="columnchart"
              type="ColumnChart"
              :data="storeyChartData"
              :resizeDebounce="500"
              :options="storeyChartOptions"
            />

            <GChart
              class="columnchart"
              type="ColumnChart"
              :data="constructionTypeChartData"
              :resizeDebounce="500"
              :options="constructionTypeChartOptions"
            />

            <GChart
              class="columnchart"
              type="ColumnChart"
              :data="structureChartData"
              :resizeDebounce="500"
              :options="structureChartOptions"
            />

            <GChart
              class="columnchart"
              type="ColumnChart"
              :data="buildingCategoryChartData"
              :resizeDebounce="500"
              :options="buildingCategoryChartOptions"
            />
          </v-card>
        </v-card>
      </v-flex>
    </v-container>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  // LMarker,
  LPolygon,
  LGeoJson
} from "vue2-leaflet";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

// import redicon from 'leaflet/dist/images/marker-icon-red.png';

let DefaultIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow

  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 40] // the same for the shadow
});

const myMarkerIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 41], // the same for the shadow
  popupAnchor: [-3, -76] // point from which the po
});

L.Marker.prototype.options.icon = myMarkerIcon;

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    LGeoJson,
    LPolygon
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      status: true,
      isPostAuthorized: true,
      isFormLoading: false,
      valid: false,
      requiredDropdown(propertyType) {
        return v => v || `${propertyType} is required`;
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      show: true,
      enableTooltip: true,
      zoom: 11,
      center: null,
      geojson: null,
      fillColor: "#4eaae6",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      color: { color: "#3F3C3C" },
      defaultIcon: DefaultIcon,
      // wards: ["1", "2", "3", "4", "5", "6"],
      // coordinates: "",
      wardName: [],
      building: {
        selectedFiscalYear: [],
        ward: [],
        building_permit: "",
        selectedConstructionType: [],
        selectedYear: [],
        selectedStorey: [],
        selectedStructure: [],
        selectedIrregularity: [],
        selectedAreaOfBuilding: [],
        selectedTypeOfRoof: [],
        selectedBuildingCategory: []
      },

      fiscalYears: [],
      wards: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30
      ],
      storey: [1, 2, 3, 4],
      year: ["1990-2050", "2050-2060", "2060-2070", "2070-2078"],
      constructionType: [],
      structure: [],
      selectedStructure: [],
      irregularity: [],
      selectedIrregularity: [],
      areaOfBuilding: [
        "less than 300 sqft.",
        "300-500 sqft.",
        "500-750 sqft.",
        "750-1000 sqft.",
        "more than 1000 sqft."
      ],
      selectedAreaOfBuilding: [],
      typeOfRoof: [],
      selectedTypeOfRoof: [],

      buildingCategory: [],
      selectedBuildingCategory: [],
      reportTable: [],
      headers: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "Description"
        },
        { text: "Number/Length", value: "Number/Length" },
        { text: "Density", value: "Density" }
      ],
      chartsData: {
        permitChartData: [],
        constructionTypeChartData: [],
        storeyChartData: [],
        yearChartData: [],
        structureChartData: [],
        areaChartData: [],
        roofChartData: [],
        buildingCategoryChartData: []
      },
      structureChartOptions: {
        title: "Building structure-wise Chart of various wards",
        height: 600,
        width: 800,
        bars: "horizontal" //for material design
      },
      permitChartOptions: {
        title: "Building permit-wise Chart of various wards",
        height: 600,
        width: 800
      },
      storeyChartOptions: {
        title: "Building storey-wise Chart of various wards",
        height: 600,
        width: 800
      },
      yearChartOptions: {
        title: "Building year-wise Chart of various wards",
        height: 600,
        width: 800
      },
      areaChartOptions: {
        title: "Building area-wise Chart of various wards",
        height: 600,
        width: 800
      },
      roofChartOptions: {
        title: "Building roof-type-wise Chart of various wards",
        height: 600,
        width: 800
      },
      buildingCategoryChartOptions: {
        title: "Building category-wise Chart of various wards",
        height: 600,
        width: 800
      },
      constructionTypeChartOptions: {
        title: "Building construction type wise Chart of various wards",
        height: 600,
        width: 800
      }
    };
  },
  created() {
    this.selectBoxApi();
  },
  mounted() {
    setTimeout(function() {
      //debugger;
      window.dispatchEvent(new Event("resize"));
      // this.marker = L.latLng(26.4525, 87.2718);
    }, 250);
  },
  computed: {
    constructionTypeChartData() {
      return [...this.chartsData.constructionTypeChartData];
    },
    permitChartData() {
      return [...this.chartsData.permitChartData];
    },
    storeyChartData() {
      return [...this.chartsData.storeyChartData];
    },
    yearChartData() {
      return [...this.chartsData.yearChartData];
    },
    structureChartData() {
      return [...this.chartsData.structureChartData];
    },
    areaChartData() {
      return [...this.chartsData.areaChartData];
    },
    roofChartData() {
      return [...this.chartsData.roofChartData];
    },
    buildingCategoryChartData() {
      return [...this.chartsData.buildingCategoryChartData];
    },
    isLoading() {
      if (this.constructionType.length === 0) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          color: "#1c7dfc",
          fillOpacity: 0.5
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        if (feature.properties.houseowner) {
          layer.bindTooltip(
            "<div>Name:<a href='https://www.google.com'>" +
              feature.properties.houseowner +
              // "</div><div>nom: " +
              // feature.properties.nom +
              "</a></div>",
            {
              permanent: true,
              sticky: true
            }
          );
        }
      };
    }
  },
  methods: {
    OnStructuralChartDataReady(chart, google) {
      const saveUrl = `api/report/building-filter`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        const options = {
          title: "Chart",
          is3D: true
        };
        console.log(response.data.permitChartData);
        chart.draw(google.visualization.arrayToDataTable(response.data.permitChartData), options);
      });
    },
    selectBoxApi() {
      const obj = this;
      //   TODO get ward list via api
      //   const wardUrl = "api/bids/select-box/ward";
      //   VueAxios.get(wardUrl)
      //     .then(response => {
      //       // debugger;
      //       obj.wardName = response.data;
      //       // debugger;
      //       console.log(obj.buildingPermanent);
      //     })
      //     .catch(error => {
      //       console.log(`api error:${error}`);
      //     });

      const fyURL = "api/fiscal-year";
      VueAxios.get(fyURL)
        .then(response => {
          // debugger;
          obj.fiscalYears = response.data.data;
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });

      const constructionTypeUrl = "api/construction-type";
      VueAxios.get(constructionTypeUrl)
        .then(response => {
          // debugger;
          obj.constructionType = response.data;
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });

      const structureUrl = "api/building-structure";
      VueAxios.get(structureUrl)
        .then(response => {
          // debugger;
          obj.structure = response.data;
          // debugger;
          console.log(obj.buildingPermanent);
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });

      const categoryUrl = "api/building-category";
      VueAxios.get(categoryUrl)
        .then(response => {
          // debugger;
          obj.buildingCategory = response.data;
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });

      const mapUrl = "api/map";
      VueAxios.get(mapUrl)
        .then(response => {
          // debugger;
          obj.geojson = response.data;
          // debugger;
          // xMin = geojson[0][0][0];
          // yMax = geojson[0][0][1];
          obj.center = L.latLng(
            obj.geojson.features[0].geometry.coordinates[0][0][1],
            obj.geojson.features[0].geometry.coordinates[0][0][0]
          );
          // obj.center = L.latLng(24, 87);
          console.log(`center: ${obj.center}`);
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      //   if(this.building.building_permit) {
      //     L.Marker.prototype.options.icon = DefaultIcon;
      //   }
      //   else {
      //     L.Marker.prototype.options.icon = DefaultIcon;
      //     // L.Marker.prototype.options.icon = RedIcon;
      //   }

      L.Marker.prototype.options.icon = DefaultIcon;

      const obj = this;

      this.chartsData.permitChartData = [];
      this.chartsData.storeyChartData = [];
      this.chartsData.yearChartData = [];
      this.chartsData.structureChartData = [];
      this.chartsData.buildingCategoryChartData = [];
      this.chartsData.constructionTypeChartData = [];
      // console.log(this.ward);

      // this.selectedIndex.push({ ward: this.ward, status: true });
      // const newData = [{ ward: this.ward, status: true }];
      // console.log(this.newData);

      let list = [];
      let selectedItem = [];

      this.isFormLoading = true;
      const saveUrl = `api/report/building-filter`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          this.isFormLoading = false;
          obj.geojson = response.data.mapdata;
          obj.center = L.latLng(
            obj.geojson.features[0].geometry.coordinates[0][0][1],
            obj.geojson.features[0].geometry.coordinates[0][0][0]
          );
          //   obj.chartsData.permitChartData = response.data.permitChartData;
          obj.chartsData.storeyChartData = response.data.storeyChartData;
          //   obj.chartsData.yearChartData = response.data.yearChartData;
          obj.chartsData.structureChartData = response.data.structureChartData;
          obj.chartsData.buildingCategoryChartData = response.data.buildingCategoryChartData;
          obj.chartsData.constructionTypeChartData = response.data.constructionTypeChartData;

          // console.log(obj.coordinates);
        })
        .catch(error => {
          // console.log(formData.toString());
        })
        .finally(() => {});

      // const url = `api/bids/report/dashboard-table`;
      // VueAxios.get(url).then(response => {
      //   // debugger;
      //   obj.reportTable = response.data.data;
      //   // console.log(obj.reportTable);debugger;
      // });
    },
    getBuildingExcelBackup() {
      // Axios.get(url, { responseType: 'blob' })
      //   .then(response => {
      //     const blob = new Blob([response.data], { type: 'application/xlsx' })
      //     const link = document.createElement('a')
      //     link.href = URL.createObjectURL(blob)
      //     link.download = label
      //     link.click()
      //     URL.revokeObjectURL(link.href)
      //   }).catch(console.error)
    }
  }
};
</script>
