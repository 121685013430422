<template>
  <div>
    <div v-if="!isDataLoaded" class="registrationAction elevation-12 my-2">
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                File Action
              </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="image">
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-card v-else class="registrationAction elevation-12 my-2">
      <v-expansion-panels v-model="opened">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> File Action </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="sober elevation-2" v-if="notForwarded">
              <template>
                <tbody>
                  <tr class="registrationtable">
                    <td class="registrationtable">
                      <v-form ref="form" v-model="valid">
                        <v-textarea v-model="forward_message.message" :rules="nameRules" placeholder="Message"
                          single-line auto-grow rows="2" class="my-3" solo></v-textarea>
                        <div>
                          <center>
                            <span v-if="$store.getters.forwardRevertActionAuthData">
                              <v-container class="px-0" fluid v-if="user_role_id == 8">
                                <v-checkbox v-model="isCheckedByConsultant"
                                  :label="'यस दरखास्त फारममा लेखिएको व्यहोरा ठीक साँचो छ, झुट्ठा ठहरे कानुन बमोजिम सहुँला बुझाउँला । '">
                                </v-checkbox>
                              </v-container>
                              <v-row v-if="revertTextLength">
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                  <custom-dialog :click-dialog="forwardToSpecificDesk" :reg-id="
                                    forwardRevertInfo.reverted_from_desk_id
                                  " :disabled="!isCheckedByConsultant" click-type="forward" class="mb-1">
                                    {{
                                    forwardRevertInfo.reverted_from_desk_text
                                    }}
                                  </custom-dialog>
                                </v-col>
                                <v-spacer></v-spacer>
                              </v-row>
                              <v-row v-else>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                  <custom-dialog :click-dialog="forward" :disabled="!(isCheckedByConsultant && valid)"
                                    click-type="forward" class="mb-1">
                                    {{ forwardButtonText }}
                                  </custom-dialog>
                                </v-col>
                                <v-col cols="3" v-if="
                                  forwardRevertInfo.list_users_flag &&
                                  forwardRevertInfo.forward_desk_id != 8
                                ">
                                  <v-select v-model="forward_message.desk_bps_user_id" label="Select user"
                                    :items="forwardRevertInfo.forward_users" item-value="id" :item-text="
                                      (row) => {
                                        return (
                                          row.first_name + ' ' + row.last_name
                                        );
                                      }
                                    ">
                                  </v-select>
                                </v-col>
                                <v-spacer></v-spacer>
                              </v-row>

                              <div class="mb-1">
                                <custom-dialog v-if="user_role_id == 8 && revertedFromUserType"
                                  :disabled="!(isCheckedByConsultant && valid)" :click-dialog="forwardToTechnical"
                                  click-type="forward">
                                  {{ revertedFromUserType }}मा फाइल पठाउनुहोस
                                </custom-dialog>
                              </div>
                              <!-- Below div code must be refactored and removed, otherwise its shame will revolve down to 10th generation -->
                              <div>
                                <custom-dialog class="mb-1" v-if="
                                  user_role_id == 2 &&
                                  regData.phase_id == 1 &&
                                  munName == 'Vyas Municipality'
                                " :click-dialog="forwardToTechnical" :disabled="!valid" click-type="forward">
                                  प्राविधिक शाखामा फाइल पठाउनुहोस
                                </custom-dialog>
                              </div>
                              <v-row class="" v-if="forwardTextLength">
                                <v-spacer></v-spacer>
                                <v-col>
                                  <v-select v-model="revertDeskSelected" :items="forwardRevertInfo.revert_desk_list"
                                    item-text="revert_text" item-value="revert_desk_id" solo></v-select>
                                </v-col>
                                <v-col cols="auto">
                                  <custom-dialog click-type="revert" :click-dialog="revertToSpecificDesk"
                                    :disabled="!valid" :reg-id="revertDeskSelected">Revert</custom-dialog>
                                </v-col>
                                <v-spacer></v-spacer>
                              </v-row>

                              <br />
                            </span>
                          </center>
                          <span v-if="message">
                            <v-alert dense outlined type="success">
                              {{ message }}
                            </v-alert>
                          </span>
                        </div>
                      </v-form>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="5000">
        {{ message }}
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "RegistrationAction",

  props: {
    regId: {
      type: Number,
    },
    regPhaseId: {
      type: Number,
    },
    regDeskId: {
      type: Number,
    },
    regCreatedByConsultancy: {
      type: Number,
    },
  },
  data() {
    return {
      opened: 0,
      valid: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      regData: [],
      munName: "",
      dialog: false,
      snackbar: {
        show: false,
        message: this.message,
        color: "success",
      },
      notificationMessage: {
        message: "",
        created_by: "",
      },
      notForwarded: true,
      show: true,
      message: "",
      is_file_reviewed_by_technical: false,
      nameRules: [(v) => !!v || "Message is required"],
      messages: [],
      forward_message: {
        message: "",
        desk_bps_user_id: "",
      },
      forwardButtonText: "",
      revertButtonText: "",
      forwardRevertInfo: {},

      roles: {
        2: { name: "Registration", next: 4, previous: 8 },
        4: { name: "Technical", next: 6, previous: 2 },
        6: { name: "Executive", next: 2, previous: 4 },
        8: { name: "Consultancy", next: 2, previous: 0 },
      },
      isCheckedByConsultant: null,

      revertedFromUserType: "",
      revertDeskSelected: null,

      isDataLoaded: false,
    };
  },
  computed: {
    isActionAuthorized() {
      // Only authorized if the file is in the user's desk
      if (this.regDeskId === this.user_role_id) {
        return true;
      }
      return false;
    },
    forwardTo() {
      const currentRole = this.roles[this.user_role_id];

      //in case of registration user
      if (this.user_role_id == 2) {
        return `प्राविधिक शाखामा फाइल पठाउनुहोस्`;
      }

      //in case of technical user
      if (this.user_role_id == 4) {
        //if in registration phase, approve text, else forward to executive desk
        return this.regPhaseId == 1
          ? `Forward to Registration`
          : `Forward to Executive`;
      }

      //in case of executive user
      if (this.user_role_id == 6) {
        return `प्रमाणित गर्नुहोस`;
      }
      //for consultancy desk
      if (this.user_role_id == 8) {
        return `दर्ता शाखामा फाइल पठाउनुहोस`;
      }

      return currentRole.next !== 0
        ? `Forward to ${this.roles[currentRole.next].name}`
        : "";
    },
    revertTo() {
      const currentRole = this.roles[this.user_role_id];

      //if in reg desk, only return "revert to consultancy" if the file was created by consultancy
      if (this.user_role_id == 2) {
        return this.regCreatedByConsultancy
          ? `Revert to ${this.roles[currentRole.previous].name}`
          : false;
      }

      //in case of executive user
      if (this.user_role_id == 6) {
        return `सच्याउन फिर्ता पठाउनुहोस`;
      }

      return currentRole.previous
        ? `Revert to ${this.roles[currentRole.previous].name}`
        : false;
    },
    forwardTextLength() {
      return this.forwardRevertInfo.revert_desk_list
        ? this.forwardRevertInfo.revert_desk_list.length
        : 0;
    },
    revertTextLength() {
      return this.forwardRevertInfo.reverted_from_desk_text
        ? this.forwardRevertInfo.reverted_from_desk_text.length
        : 0;
    },
  },
  created() {
    this.getMunicipalityName();
    this.getRevertedFromUserType();
  },
  mounted() {
    if (this.user_role_id !== 8) {
      this.isCheckedByConsultant = true;
    }
    // if(this.user_role_id === 8) {
    //   console.log("test testaa tset");
    //   this.forward_message.message = "कृपया फाइल दर्ता गरिदिनु होला।";
    //   console.log(this.forward_message.message);
    // }
    this.forwardAndRevert();
    // console.log(this.isCheckedByConsultant);
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(url).then((response) => {
      // debugger;
      this.regData = response.data.data;
      // console.log(response.data.data);
    });

    const messageUrl = `api/registration/${this.$route.params.id}/message`;
    VueAxios.get(messageUrl)
      .then((response) => {
        // debugger;
        this.notificationMessage = response.data.data;
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });

    const technicalReviewUrl = `api/registration/${this.$route.params.id}/technical-review-status`;
    VueAxios.get(technicalReviewUrl)
      .then((response) => {
        // debugger;
        this.is_file_reviewed_by_technical = response.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });

    const forwardRevertMessagesUrl = `api/registration/${this.$route.params.id}/forward-revert-messages`;
    VueAxios.get(forwardRevertMessagesUrl)
      .then((response) => {
        // debugger;
        this.messages = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    getMunicipalityName() {
      const url = `api/municipality`;
      this.$axios.get(url).then((response) => {
        this.munName = response.data.data[0].name;
      });
    },
    forwardAndRevert() {
      this.isDataLoaded = false
      const url = `api/registration/${this.$route.params.id}/forward-revert-desk-info`;
      this.$axios.get(url).then((response) => {
        if (response.status == 200) {
          this.forwardButtonText = response.data.forward;
          this.revertButtonText = response.data.revert;

          this.forwardRevertInfo = response.data;
          this.isDataLoaded = true;
        }
      }).catch(error => {
        console.log(error);
        this.isDataLoaded = true;
      });
    },
    forward() {
      const url = `/api/registration/${this.$route.params.id}/forward`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.snackbar.color = "success";
            this.snackbar.show = true;
            this.message = "Forwarded successfully.";
            this.notForwarded = false;
            // this.$router.go(); Very Bad method to reload the page, do another mehthod
            // Another method 1
            // this.$root.$emit("application_tab");
            // refresh application log card
            // this.$root.$emit("owner-info-card");
            this.$root.$emit("onFileForwardRevert");
          }
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!";
          this.message = error.response.data.message;
          console.log(`api error:${error}`);
        });
    },
    forwardToTechnical() {
      const url = `/api/registration/${this.$route.params.id}/forward/technical`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.notForwarded = false;
            this.message = "Forwarded successfully.";
          }
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!";
          this.message = error.response.data.message;
          console.log(`api error:${error}`);
        });
    },
    revert() {
      const url = `/api/registration/${this.$route.params.id}/revert`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.notForwarded = false;
            this.snackbar.color = "success";
            this.snackbar.show = true;
            this.message = "Reverted successfully.";
          }
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!!";
          this.message = error.response.data.message;
          console.log(`api error:${error}`);
        });
    },
    revertToConsultant() {
      const url = `/api/registration/${this.$route.params.id}/revert/consultant`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.notForwarded = false;
            this.message = "Reverted successfully.";
            this.snackbar.color = "success";
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!!";
          this.message = error.response.data.message;
        });
    },
    revertToSpecificDesk(desk_id) {
      const url = `/api/registration/${this.$route.params.id}/revert-to-specific-desk/${desk_id}`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.notForwarded = false;
            this.message = "Reverted successfully.";
            this.snackbar.color = "success";
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!!";
          this.message = error.response.data.message;
        });
    },
    forwardToSpecificDesk(desk_id) {
      const url = `/api/registration/${this.$route.params.id}/forward-to-specific-desk/${desk_id}`;
      VueAxios.post(url, this.forward_message)
        .then((response) => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.notForwarded = false;
            this.message = "Forwarded successfully.";
            this.snackbar.color = "success";
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.snackbar.color = "error";
          this.snackbar.show = true;
          // this.message = "Error!!";
          this.message = error.response.data.message;
        });
    },
    getNextDesk(currentdeskid) {
      if (currentdeskid === 2) {
        return "Technical Desk";
      }
      if (currentdeskid === 4) {
        return "Executive Desk";
      }

      return "";
    },
    getPreviousDesk(currentdeskid) {
      if (currentdeskid === 6) {
        return "Technical Desk";
      }
      if (currentdeskid === 4) {
        return "Registration Desk";
      }

      return "";
    },
    getRevertedFromUserType() {
      const roleUrl = `api/registration/${this.$route.params.id}/revert-from-user-type`;
      this.$axios
        .get(roleUrl)
        .then((response) => {
          this.revertedFromUserType = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.forward {
  margin-top: -15px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
</style>
