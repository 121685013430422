<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form>
          <v-container height="auto">
            <v-row height="auto">
              <v-col md="12">
                <v-card>
                  <h3 class="d-flex justify-center">Application Status</h3>
                  <br />
                  <div v-if="!isDataLoaded">
                    <v-skeleton-loader type="table"></v-skeleton-loader>
                  </div>
                  <div v-else>
                    <v-simple-table class="sober">
                      <template v-slot:default>
                        <thead>
                          <tr class="registrationtable">
                            <!-- <th class="text-left registrationtable">Level</th> -->
                            <th class="text-left registrationtable" v-for="p in applicationHeader" :key="p">
                              {{ p }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="registrationtable" v-for="applicationStatusList in applicationStatus"
                            :key="applicationStatusList.id">
                            <td class="registrationtable" v-for="key in applicationHeader" :key="key">
                              <span v-html="applicationStatusList[key]">{{ applicationStatusList[key] }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Component1",
  props: {
    id: {
      type: String
    },
    nextStep: {
      type: Function
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  data() {
    return {
      applicationStatus: [],
      applicationHeader: [],
      isDataLoaded: false,
    };
  },
  created() {
    this.getApplicationStatus();
  },
  methods: {
    getApplicationStatus() {
      this.isDataLoaded = false;
      const url = `api/registration/${this.$route.params.id}/application-status`;
      this.$axios.get(url).then(response => {
        this.applicationStatus = response.data;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
        this.isDataLoaded = true;
        // console.log(this.applicationStatus);
      }).catch(error => console.log(`api error:${error}`))
    }
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
