<template>
  <b-container class="white rounded-lg">
    <div>
      <h3>Ward Responsibility for Technical Users</h3>
    </div>
    <div>
      <v-row v-for="i in ward_responsibility_matrix" :key="i.id">
        <v-col cols="3">
          <v-select
            v-model="i.phase_id"
            :items="phaseList"
            item-value="phase_id"
            item-text="phase_name"
            label="Phase"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="i.user_full_name" readonly></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="i.assigned_wards"
            :items="wardList"
            label="Wards"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <!-- <div>
        <v-simple-table>
          <thead>
            <tr>
              <th>Users</th>
              <th v-for="i in phaseList" :key="i">{{ i.phase_name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="j in users" :key="j">
              <td>{{ j }}</td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </div> -->

      <div class="ma-4">
        <v-btn color="primary" @click="save">Save</v-btn>
      </div>
    </div>
    <div>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      ward_responsibility_matrix: [],
      // ward_responsibility_matrix:
      //   [
      //     {
      //       id: 1,
      //       phase_id: 1,
      //       user_id: 2,
      //       assigned_wards: {
      //         phase_one: [1,3],
      //         phase_two: [4,5],
      //         phase_three: [8,9],
      //         phase_four: [9,10],
      //         phase_five: [1,2]
      //       },
      //       user_full_name: "User One",
      //     },
      //     {
      //       id: 2,
      //       phase_id: 2,
      //       user_id: 3,
      //       assigned_wards: [12, 13],
      //       user_full_name: "User Two",
      //     },
      //   ],

      // phaseList: [
      //   "कागजात जाँच तथा दर्ता",
      //   "१५ दिने सूचना (सर्जमिन)",
      //   "प्लिन्थ निर्माण अनुमति",
      //   "सुपरस्ट्रक्चर निर्माण अनुमति",
      //   "निर्माण सम्पन्न",
      // ],
      phaseList: [],
      wardList: [],
      //   userList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      users: [],
    };
  },
  created() {
    //   First let's get the options for the select fields
    this.getPhaseList();
    this.getWardList();

    this.getWardResponsibilityMatrix();
  },
  methods: {
    jsonParseWardList() {
      this.ward_responsibility_matrix.forEach((i) => {
        i.assigned_wards = JSON.parse(i.assigned_wards);
        // users.add({ name: i.user_full_name, user_id: i.user_id });
      });
      let users = [
        ...new Set(
          this.ward_responsibility_matrix.map((item) => item.user_full_name)
        ),
      ];
      this.users = users;
      // ... are spread attributes
      // var parts = ['two', 'three'];
      // var numbers = ['one', ...parts, 'four', 'five']; // ["one", "two", "three", "four", "five"]
    },
    getWardResponsibilityMatrix() {
      const matrixUrl = `api/ward-responsibility-matrix`;
      this.$axios
        .get(matrixUrl)
        .then((response) => {
          this.ward_responsibility_matrix = response.data.data;
          this.jsonParseWardList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPhaseList() {
      const pUrl = "api/phase-list";
      this.$axios
        .get(pUrl)
        .then((response) => {
          this.phaseList = response.data.data;
        })
        .catch((error) => {
          console.log("Phase List not loaded");
          console.log(error.response.data.message);
        });
    },
    getWardList() {
      const municipalityUrl = `api/municipality`;
      this.$axios
        .get(municipalityUrl)
        .then((response) => {
          if (response.status == 200) {
            let noOfWards = response.data.data[0].no_of_wards;
            console.log(`no of wards: ${noOfWards}`);
            for (let i = 0; i < noOfWards; i++) {
              this.wardList.push(i + 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      console.log("Save init");
      const saveUrl = `api/ward-responsibility-matrix/1`;
      this.$axios
        .put(saveUrl, {
          ward_responsibility_matrix: this.ward_responsibility_matrix,
        })
        .then((response) => {
          if (response.status == 200) {
            this.snackbar.show = true;
            this.successfulMessage = "Data updated successfully";
            this.ward_responsibility_matrix = response.data.data;
            this.jsonParseWardList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>