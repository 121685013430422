<style scoped>
.datepicker {
  height: 60px !important;
}
</style>
<template>
  <b-container>
    <v-form ref="form" v-model="valid" style="margin-bottom: 1rem">
      <h4>General Information</h4>
      <v-subheader
        >[<strong>Note</strong>: Basic Information of consultancy]</v-subheader
      >
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.first_name"
            label="First Name"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.last_name"
            label="Last Name"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.designation"
            :rules="[rules.required, rules.counter]"
            label="Designation"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.name"
            :rules="[rules.required, rules.counter]"
            label="Company Name"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.name_np"
            :rules="
              requiredFormat(
                'परामर्श नाम',
                '^[\u0900-\u0966 \u0970-\u097F \.]+$'
              )
            "
            label="Company Name in Nepali"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider inset></v-divider>

      <h4>Address Information</h4>
      <v-subheader
        >[<strong>Note</strong>: Address Information of
        consultancy]</v-subheader
      >
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="consultancy.district_id"
            :items="districts"
            item-text="district_name"
            item-value="id"
            outlined
            label="District"
            required
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="consultancy.location"
            label="Location"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider inset></v-divider>

      <h4>Registered Information</h4>
      <v-subheader
        >[<strong>Note</strong>: Registered Information of
        consultancy]</v-subheader
      >
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="consultancy.registration_number"
            label="Registration Number"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <!-- <v-text-field
            v-model="consultancy.registration_date"
            label="Registration Date"
            outlined
            clearable
          ></v-text-field> -->

          <!-- <div id="old_nepali_date">
            {{ consultancy.registration_date_np }}
          </div> -->
          <nepali-date-picker
            v-model="consultancy.registration_date"
            outlined
          ></nepali-date-picker>

          <!-- <v-nepalidatepicker
            placeholder="Registration Date"
            calenderType="Nepali"
            classValue="form-control"
            v-model="consultancy.registration_date_np"
          /> -->
        </v-col>
        <v-col cols="4">
          <v-file-input
            v-model="consultancy.renew_certificate"
            label="Select File Renew Certificate"
            outlined
            clearable
          ></v-file-input>
        </v-col>
        <v-col cols="3" v-if="renew_certificate != null">
          <pdf-dialog
            :src="basePath + renew_certificate"
            displayText="Full Screen"
          ></pdf-dialog>
          <br />
          <inline-doc :src="basePath + renew_certificate"></inline-doc>
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ConsultancyMember />
        </v-col>
      </v-row>
      <v-checkbox v-model="agreeCheckbox" label="I agree to terms and conditions Change it later"></v-checkbox>
      <v-btn
        class="mr-4"
        color="primary"
        elevation="2"
        @click="updateConsultancyProfile(consultancy.id)"
        :disabled="!agreeCheckbox"
      >
        Update
      </v-btn>
      <v-btn color="primary" elevation="2" outlined> Cancel </v-btn>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
  </b-container>
</template>

<script>
import ConsultancyMember from "./ConsultancyMember.vue";
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";

export default {
  components: {
    ConsultancyMember,
    NepaliDatePicker,
  },
  props: {
    fileSize: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    email: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    required(propertyType) {
      return (v) => !!v || `${propertyType} is required`;
    },
    requiredFormat(propertyType, regexexp) {
      var regex = new RegExp(regexexp, "g");
      return [
        (v) => !!v || `${propertyType} is reqiuired`,
        (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
      ];
    },
    requiredPhone(propertyType) {
      return [
        (v) => !!v || `${propertyType} is required`,
        (v) =>
          /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) ||
          `${propertyType} is not valid phone number`,
      ];
    },
    districts: [],
    consultancy: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      name: "",
      name_np: "",
      district_id: "",
      location: "",
      registration_number: "",
      registration_date: "",
      renew_certificate: "",
    },
    agreeCheckbox: false,
    renew_certificate: null,
    basePath: process.env.VUE_APP_BASE_URL,
    successfulMessage: "",
    snackbar: {
      show: false,
      color: "success",
    },
    formData: new FormData(),
    fileSizeValidate() {
      return [
        (v) =>
          !v ||
          v.size < this.fileSize * 1000000 ||
          `File should be less than ${this.fileSize} MB`,
      ];
    },
  }),
  created() {
    this.getConsultancyData();
    this.getDistricts();
  },
  watch: {
    renew_certificate() {
      console.log(`renew cert: ${this.renew_certificate}`);
      this.renew_certificate = this.consultancy.renew_certificate;
    },
  },
  computed: {
    // renew_certificate(){
    //   return this.consultancy.renew_certificate;
    // }
  },
  methods: {
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      // const formData = new FormData();
      this.buildFormData(this.formData, data);
      return this.formData;
    },
    getConsultancyData() {
      const conDataUrl = `api/consultancy-profile`;
      this.$axios
        .get(conDataUrl)
        .then((response) => {
          if (response.status === 200) {
            this.consultancy = response.data.data[0];
            this.renew_certificate = this.consultancy.renew_certificate;
            // console.log(`consultancy: ${this.consultancy.renew_certificate}`);
          }
        })
        .catch((error) => {
          console.log(`consultancy api error:${error}`);
        });
    },
    getDistricts() {
      const districtUrl = "api/districts";
      this.$axios
        .get(districtUrl)
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    updateConsultancyProfile(id) {
      const url = `api/consultancy-profile/${id}`;
      const self = this;
      // this.renew_certificate = this.consultancy.renew_certificate;
      this.formData = this.jsonToFormData(this.consultancy);
      self.formData.append("_method", "PUT");
      this.$axios.post(url, self.formData).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.consultancy = response.data.data;
          // this.renew_certificate = response.data.data.renew_certificate;

          this.snackbar.show = true;
          this.successfulMessage = "Profile Updated Successfuly.";
        }
      });
    },
  },
};
</script>
