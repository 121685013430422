<template>
  <div>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> भवनको विवरण </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6" class="my-8">
        <old-reg-nibedan-form
          :isPostAuthorized="isPostAuthorized"
          :required="required"
          ref="childNibedanForm"
        >
        </old-reg-nibedan-form>
      </v-col>
    </v-row>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> घरधनीको विवरण </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <old-reg-ghardhani-form
          :isPostAuthorized="isPostAuthorized"
          ref="childGhardhaniForm"
        ></old-reg-ghardhani-form>
      </v-col>
      <v-col cols="6">
        <old-reg-ghardhani-list></old-reg-ghardhani-list>
      </v-col>
    </v-row>
    <!-- <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        जग्गाको विवरण (क्षेत्रफल)
      </v-toolbar-title>
    </v-toolbar> -->
    <v-row>
      <v-col cols="6" class="">
        <old-reg-land-area-form
          :isPostAuthorized="isPostAuthorized"
          ref="childLandAreaForm"
        ></old-reg-land-area-form>
      </v-col>
      <v-col cols="6" class="">
        <old-reg-land-area-list></old-reg-land-area-list>
      </v-col>
    </v-row>
    <!-- This save all button is removed for now due to several bugs in this method, soon t
    to be refactored -->

    <!-- <v-row>
      <v-col>
        <v-btn primary v-if="isPostAuthorized" v-on:click="saveAll" class="green white--text mb-4"
          >Save All</v-btn
        >
      </v-col>
    </v-row> -->
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> कागजातहरु </v-toolbar-title>
    </v-toolbar>
    <v-snackbar v-model="snackbar.show" :timeout="4000" color="success"
      >All data updated successfully</v-snackbar
    >
  </div>
</template>

<script>
import OldRegNibedanForm from "./nibedan/OldRegNibedanForm.vue";
import OldRegGhardhaniForm from "./ghardhani/OldRegGhardhaniForm.vue";
import OldRegGhardhaniList from "./ghardhani/OldRegGhardhaniList.vue";
import OldRegLandAreaForm from "./landarea/OldLandAreaForm.vue";
import OldRegLandAreaList from "./landarea/OldLandAreaList.vue";
export default {
  components: {
    OldRegNibedanForm,
    OldRegGhardhaniForm,
    // OldRegGhardhaniList,
    // OldRegLandAreaForm
    // OldRegLandAreaList
  },
  data() {
    return {
      // required(propertyType) {
      //   return v => !!v || `${propertyType} is required`;
      // },
      // requiredUnicode(propertyType) {
      //   return [
      //     v => !!v || `${propertyType} is required`,
      //     // v => /^\W$/.test(v) || 'nice one'
      //     v => /^[\W]+$/.test(v) || `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`
      //   ];
      // },
      // requiredFormat(propertyType, regexexp) {
      //   var regex = new RegExp(regexexp, "g");
      //   return [
      //     v => !!v || `${propertyType} is reqiuired`,
      //     v => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`
      //     // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
      //   ];
      // },
      // requiredPhone(propertyType) {
      //   return [
      //     v => !!v || `${propertyType} is required`,
      //     // v => /^\w$/ || 'Please write in english format',
      //     v =>
      //       /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) || `${propertyType} is not valid phone number`
      //   ];
      // }
      user_role_id: localStorage.getItem("auth_user_role_id"),
      snackbar: {
        show: false,
        successfulMessage: "",
      },
      reg: [],
    };
  },
  computed: {
    isPostAuthorized() {
      if (this.user_role_id == 2 || this.user_role_id == 8) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // console.log(this.user_role_id);
    const urlApp = `api/registration/${this.$route.params.id}`;
    this.$axios.get(urlApp).then((response) => {
      this.reg = response.data.data;
    });
  },
  methods: {
    required(propertyType) {
      return (v) => !!v || `${propertyType} is required`;
    },

    saveAll() {
      this.$refs.childGhardhaniForm.save();
      // this.$refs.childLandAreaForm.save();
      this.$refs.childNibedanForm.save();
      this.snackbar.show = true;
      this.snackbar.successfulMessage = "All Data updated successfully";
    },
  },
};
</script>
