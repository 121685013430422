<template>
  <div>
    <nav class="top-nav white"></nav>
    <aside class="side-nav white">
    </aside>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top-nav {
  /* background-color: red; */
  height: 8rem;
}
.side-nav {
  /* display: flex; */
  /* flex-direction: column; */
  /* background-color: white; */
  height: 100vh; /* Change */
  width: 16rem;
}
</style>