<template>
  <v-card height="automatic">
    <div class="custom_map">
      <l-map :zoom="zoom" :center="center" @click="add" v-resize="onResize" :value="value" ref="mymap">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
        <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
      </l-map>
    </div>
  </v-card>
</template>
<script>
// import { EventBus } from "../../main.js";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson, LPolyline } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
  },
  props: {
    value: {
      type: [Object, Array],
    },
    roadValue: { type: Array },

    cordinateType: {
      // type: [Array],
      type: [String, Number],
    },
    inputLatitude: String,
    inputLongitude: String,
    disableMarker: Boolean,
  },
  data() {
    const myMarkerIcon = L.icon({
      // iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      iconUrl: "/images/home_marker_icon.png",
      shadowUrl:
        "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
    });
    return {
      coordinates: [],
      cordinateValue: {},
      cordinateList: [],
      // latitude: 0,
      // longitude: 0,
      dataType: "",
      zoom: 11,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(27.72243591897343, 85.3143310546875),
      lat: "27.72243591897343",
      long: "85.3143310546875",
      geojson: { features: [] },
      // options: { color: "#555555" },
      features: [
        {
          type: "Feature",
          properties: { party: "Road" },
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      ],
      fillColor: "#4eaae6",
    };
  },
  created() {
    // this.getMapJson();
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          color: "#1c7dfc",
          fillOpacity: 0.5,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => { };
      }
      return (feature, layer) => {
        if (feature.properties.houseowner) {
          layer.bindTooltip(
            "<div>Name:" +
            feature.properties.houseowner +
            // "</div><div>nom: " +
            // feature.properties.nom +
            "</div>",
            {
              permanent: false,
              sticky: true,
            }
          );
        }
      };
    },
    // getLatitude: function() {
    //   return this.latitude;
    // },
    // getLongitude: function() {
    //   return this.longitude;
    // }
    getMarker: function () {
      return [this.inputLatitude, this.inputLongitude]; // returns the marker value
    },
  },
  mounted() {
    this.getMapJson();
    // this.setMarker(); // for intially setting the marker after center init.
  },
  watch: {
    // Watching the above computed getMarker function
    getMarker() {
      // console.log(`watch marker: ${this.marker}`);
      return (this.marker = L.latLng(this.inputLatitude, this.inputLongitude));
    },
  },
  methods: {
    add(e) {
      if (this.disableMarker) {
        this.$emit("marker", this.coordinates);
      }
      else {
        this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
        this.coordinates = [e.latlng.lat, e.latlng.lng];
        // console.log(`marker:${this.marker}`);
        this.$emit("marker", this.coordinates);
        // this.$emit("shown");
      }

    },
    getMapJson() {
      const mapUrl = "api/map";
      this.$axios
        .get(mapUrl)
        .then((response) => {
          // debugger;
          this.geojson = response.data;
          // debugger;
          this.center = L.latLng(
            this.geojson.features[0].geometry.coordinates[0][0][1],
            this.geojson.features[0].geometry.coordinates[0][0][0]
          );
          // this.marker = this.center;
          this.marker = L.latLng(this.inputLatitude, this.inputLongitude)
          // this.center = L.latLng(30.02, 29.45);
          // console.log(`center: ${this.center}`);
          // console.log(`markerone: ${this.marker}`);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    onResize() {
      // This function is very important when map is shown in a dialog or modal. Because resize
      // do not occur, we have to do it from this to render the whole map
      // console.log(`hellomap: ${this.$refs.mymap.mapObject}`);
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
    // setMarker() {
    //   this.marker = L.latLng(this.inputLatitude, this.inputLongitude);
    // },
  },
};
</script>
<style scoped>
.custom_map {
  width: 100%;
  height: 350px;
}
</style>
