<template>
  <b-container fluid class="mx-0">
    <!-- <v-row>
      <v-col>
        <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <owner-info-card></owner-info-card>
      </v-col>
    </v-row> -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <template>
      <v-row justify="center">
        <v-dialog persistent v-model="dialog" max-width="800">
          <v-card>
            <v-card-title>
              <span class="text-h5"><strong>छाेटाे विवरण</strong></span>
            </v-card-title>
            <v-card-text class="ma-0 py-0">
              <div>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      dense
                      label="आर्थिक बर्ष"
                      v-model="registration.fiscal_year_id"
                      :rules="[required('आर्थिक बर्ष')]"
                      :items="fiscalyear"
                      item-value="id"
                      item-text="f_year"
                      :item-key="fiscalyear"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="registration.houseowner_name_np"
                      dense
                      label="घरधनीको नाम"
                      :rules="[required('घरधनीको नाम')]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="6">
                    <v-select
                      dense
                      v-model="registration.construction_type_id"
                      label="निर्माणको विवरण"
                      :rules="[required('निर्माणको विवरण')]"
                      :items="constructiontype"
                      item-value="construction_type_id"
                      item-text="construction_type_name"
                      :item-key="constructiontype"
                    ></v-select>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="registration.land_plot_no"
                      label="जग्गा कित्ता न"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="registration.file_code"
                      label="File Code"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="registration.ward_no"
                      label="साबिक वडा न."
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-checkbox v-model="haveCoordinates" label="I have coordinates"></v-checkbox>
                </v-row>
                <v-row v-if="haveCoordinates == true">
                  <v-col cols="6">
                    <v-text-field
                      v-model="registration.longitude"
                      label="Longitude"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="registration.latitude" label="Latitude" dense required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <Map
                      @marker="addCoordinates($event)"
                      :inputLatitude="registration.latitude"
                      :inputLongitude="registration.longitude"
                    ></Map>
                  </v-col>
                  <v-col> </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :to="{ name: 'old_registration_dashboard' }" depressed>
                Cancel
              </v-btn>
              <v-btn color="primary" v-on:click="save">
                <!-- अगाडि बढ्नुहोस -->
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </b-container>
</template>

<script>
import Map from "./map/Map.vue";
export default {
  components: {
    Map
  },
  data() {
    return {
      dialog: true,
      isLoading: false,
      haveCoordinates: false,
      registration: {
        old_reg_id: 0,
        fiscal_year_id: "",
        registration_date_np: "",
        submitted_date_np: "",
        file_code: "",
        ward_no: "",
        houseowner_name: "",
        houseowner_name_np: "",
        construction_type_id: "1",
        land_plot_no: "",
        latitude: "",
        longitude: ""
      },
      constructiontype: [],
      fiscalyear: [],
      required(propertyType) {
        return v => !!v || `${propertyType} is required`;
      }
    };
  },
  created() {
    this.constructionType();
    this.getFiscalYear();
  },
  mounted() {
    this.getDate();
  },
  // computed: {
  //   this.data.latitude =
  // },
  methods: {
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then(response => {
          // this.registration.submitted_date_np = response.data.date_np;
          this.registration.submitted_date_np = response.data.date_np;
          // this.registration.registration_date = response.data.date;
        })
        .catch(() => {});
    },
    addCoordinates(coordinates) {
      // https://dev.to/freakflames29/how-to-pass-data-from-child-to-parent-in-vue-js-2d9m for reference to $emit
      this.registration.latitude = coordinates[0];
      this.registration.longitude = coordinates[1];
    },
    constructionType() {
      const constructionurl = "api/construction-type";
      this.$axios
        .get(constructionurl)
        .then(response => {
          this.constructiontype = response.data;
        })
        .catch(() => {});
    },
    getFiscalYear() {
      const fiscalUrl = "api/fiscal-year";
      this.$axios
        .get(fiscalUrl)
        .then(response => {
          this.fiscalyear = response.data.data;
          console.log("Fiscal Year ID: " + this.fiscalyear[this.fiscalyear.length - 1]["id"]);
          this.registration.fiscal_year_id = this.fiscalyear[this.fiscalyear.length - 1]["id"];
        })
        .catch(() => {});
    },
    save() {
      // start the loading spinner
      this.isLoading = true;
      console.log(this.registration);
      const url = "api/registration/old";
      this.$axios
        .post(url, this.registration)
        .then(response => {
          this.isLoading = false;
          this.$router.push({
            name: "old_registration_application_tab",
            params: { id: response.data.data.id }
          });
          // console.log(`nice: ${response.data.data.id}`)
          if (response.error != null) {
            this.message = error.response.data.message;
          } else {
            // this.token = response.data.access_token;
            // console.log(response.data.access_token);
            // localStorage.setItem('token', response.data.access_token);
            console.log(localStorage.getItem("token"));
          }
        })
        .catch(error => {
          // this.isLoading = false;
        });
    }
  }
};
</script>
