<template>
  <div>
    <v-row>
      <v-col>
        <MemberForm />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MemberForm from "./inputforms/MemberForm.vue";

export default {
  components: {
    MemberForm,
  },
};
</script>
