<template>
  <div>
    <v-card>
      <v-card-title
        >{{ regData.houseowner_name_np }} &nbsp;
        <v-btn v-if="!edit" class="primary" @click="edit = !edit"
          >Edit Info</v-btn
        >
      </v-card-title>
      <v-card-text>
        <div v-if="edit">
          <v-row>
            <v-col cols="2">घरधनीको नाम</v-col>
            <v-col cols="2">
              <v-text-field
                v-model="regData.houseowner_name_np"
                hide-details="true"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">आर्थिक बर्ष</v-col>
            <v-col cols="2">
              <v-autocomplete
                dense
                v-model="regData.fiscal_year_id"
                :items="fiscalyear"
                item-value="id"
                item-text="f_year"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="2">
            <strong>जग्गाको कित्ता</strong>
          </v-col>
          <v-col cols="2" v-if="!edit">
            {{ engNumToNep(regData.land_plot_no) }}
          </v-col>
          <v-col cols="2" v-else>
            <v-text-field
              v-model="regData.land_plot_no"
              hide-details="true"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2"> <strong>फाइल कोड</strong></v-col>
          <v-col cols="2" v-if="!edit">
            {{ engNumToNep(regData.file_code) }}
          </v-col>
          <v-col cols="2" v-else>
            <v-text-field
              v-model="regData.file_code"
              hide-details="true"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-btn v-if="edit" class="primary ma-2" @click="save">Save</v-btn>
      <v-row>
        <v-col cols="4">
          <v-snackbar
          v-model="message.show"
          color="success"
          timeout="3000"
        >
          {{ message.data }}
        </v-snackbar>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { engNumConvert } from "@/modules/mixins/numericToUnicode.js";

export default {
  name: "OldRegistrationInfoCard",
  mixins: [engNumConvert],
  data() {
    return {
      edit: false,
      regData: {},
      fiscalyear: "",
      message: {
        data: "Data saved successfully",
        show: false,
      },
    };
  },
  created() {
    this.getRegistrationData();
    this.getFiscalYear();
  },
  methods: {
    getRegistrationData() {
      const regUrl = `api/registration/${this.$route.params.id}`;
      this.$axios.get(regUrl).then((response) => {
        this.regData = response.data.data;
      });
    },
    getFiscalYear() {
      const fiscalUrl = "api/fiscal-year";
      this.$axios
        .get(fiscalUrl)
        .then((response) => {
          this.fiscalyear = response.data.data;
        })
        .catch(() => {});
    },
    save() {
      const regDataUrl = `api/registration/${this.$route.params.id}`;
      this.$axios
        .put(regDataUrl, this.regData)
        .then((response) => {
          this.regData = response.data.data;
          this.message.show = true;
          setTimeout(() => {
            this.message.show = false;
          }, 3000);
          this.edit = !this.edit;
        })
        .catch((error) => {
          console.log(`registration put api error:${error}`);
          // this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>