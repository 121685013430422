<template>
  <v-app>
    <Navbar />
    <!-- <v-card
      class="d-flex mx-10 mt-3 mb-10 pb-10 pd-3 justify-center"
      style="box-shadow: none"
    > -->
    <div class="mx-3 my-3">
      <router-view></router-view>
    </div>
    <!-- </v-card> -->
    <!-- <v-container fluid class="mx-0">
      <router-view></router-view>
    </v-container> -->
    <!-- <Footer /> -->
  </v-app>
</template>
<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
export default {
  name: "App",
  components: {
    Navbar,
    // Footer,
  },
};
</script>

